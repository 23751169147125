<template>
    <div class="homePage">
        <el-carousel>
            <el-carousel-item v-for="(url,index) in pictureList" :key="index">
                <el-image
                        class="carousel-img"
                        :src="url"
                        fit="cover"></el-image>
            </el-carousel-item>
        </el-carousel>

        <div class="chanpin" style="margin-top: 40px">
            <el-row>
                <el-col class="chanpin-left" style="height: 520px;"
                        :lg="{span:8,offset:3} " :md="{span:10,offset:6}" :sm="{span:10,offset:7}"
                        :xs="{span:16,offset:4}">
                    <el-row>
                        <el-col>
                            <img style="width:98%;border:5px solid rgba(160,193,95,0.2);"
                                 :src=currentUrl>
                        </el-col>

                    </el-row>
                    <el-row>
                        <el-col>
                            <img style="width:100%;height: 120px" src="@/../static/iamges/home/home_bg_250_80.png">
                        </el-col>
                    </el-row>
                    <el-row style="position: relative; top: -115px;" class="chanpin-pic">
                        <el-col :lg="{span:1,offset:1}" :md="{span:1,offset:1}" :sm="{span:1,offset:1}"
                                :xs="{span:1,offset:1}">
                            <img style="margin-top: 30px" src="@/../static/iamges/home/home_piconl.png"
                                 @click="ShowProductPic2()">
                        </el-col>
                        <el-col v-for="(item,index) in productPic" :key="index " :lg="{span:5,offset:1}"
                                :md="{span:5,offset:1}" :sm="{span:5,offset:1}" :xs="{span:5,offset:1}">
                            <img class="img-home-p2" style="width: 88%;border:4px solid rgba(160,193,95,0.2)"
                                 :src="item.url">
                        </el-col>
                        <!--                       <el-col :lg="{span:6}" :md="{span:6}" :sm="{span:5}" :xs="{span:5}">-->
                        <!--                           <img  class="img-home-p2" style="width: 85%;border:4px solid rgba(160,193,95,0.2)" src="@/../static/iamges/home/scan1.jpg">-->
                        <!--                       </el-col>-->
                        <!--                       <el-col :lg="{span:6}" :md="{span:6}" :sm="{span:5}" :xs="{span:5}">-->
                        <!--                           <img  class="img-home-p2" style="width: 85%;border:4px solid rgba(160,193,95,0.2)" src="@/../static/iamges/home/scan2.jpg">-->
                        <!--                       </el-col>-->
                        <el-col :lg="{span:1}" :md="{span:1}" :sm="{span:1}" :xs="{span:1}">
                            <img style="margin-top: 30px;margin-left: 10px"
                                 src="@/../static/iamges/home/home_piconr.png" @click="ShowProductPic(productPic)">
                        </el-col>

                    </el-row>
                </el-col>
                <el-col class="chanpin-right" :lg="{span:6,offset: 2}">
                    <img src="@/../static/iamges/home/home_pright-1.png">
                </el-col>

            </el-row>
            <el-row style="margin-top: 120px;padding-bottom: 40px">
                <el-col :lg="{span:16,offset:3}" style="background-color: #f8f8f8;">

<!--                    <el-row>-->
<!--                        <el-col :lg="{span:6,offset:0}" style="margin-top: 20px;margin-left: -25px">-->
<!--                            <img src="@/../static/iamges/home/home_bg_title.png">-->
<!--                        </el-col>-->

<!--                    </el-row>-->
<!--                    <el-row style="padding-bottom: 10px" >-->

<!--                        <el-col :lg="{span:6,offset:0}"-->

<!--                                style="color: #ffffff;font-size: 20px;font-weight: 600;margin-left: -25px;position: relative; margin-top: -34px;-->
<!--                                      text-decoration: underline #ffffff">-->
<!--                            <div @click="gotoSlide()">-->
<!--                                病理切片-->
<!--                            </div>-->

<!--                        </el-col>-->


<!--                    </el-row>-->



                    <el-row>
                        <el-col :lg="{span:6,offset:0}" style="margin-top: 20px;margin-left: -25px">
                            <img src="@/../static/iamges/home/home_bg_title.png">
                        </el-col>

                    </el-row>
                    <el-row>
                        <el-col :lg="{span:6,offset:0}"
                                style="color: #ffffff;font-size: 20px;font-weight: 600;margin-left: -25px;position: relative; margin-top: -34px;">
                            产品概述
                        </el-col>
                    </el-row>
                    <p style="text-align: left;padding-left:45px;line-height: 30px">
                        便携式数字切片扫描仪是图灵基因自主研发的光学扫描影像设备，通过攻克超高分辨率扫描技术，采用自有知识产权的微型化结构，
                        实现了自动对焦、自动扫描、自动拼接，且重量轻、体积小、便于携带，可用于远程会诊、科学研究、形态学教学及病理AI领域。</p>
                    <el-row style="margin-top: -20px ">
                        <el-col :lg="{span:6,offset:0}" style="margin-top: 20px;margin-left: -25px">
                            <img src="@/../static/iamges/home/home_bg_title.png">
                        </el-col>

                    </el-row>
                    <el-row>
                        <el-col :lg="{span:6,offset:0}"
                                style="color: #ffffff;font-size: 20px;font-weight: 600;margin-left: -25px;position: relative; margin-top: -34px;">
                            产品参数
                        </el-col>
                    </el-row>


                    <el-row style="margin-top: 15PX">
                        <el-col :lg="{span:6}" :md="{span:6}" :sm="{span:6}" :xs="{span:18 ,offset:3}"
                                class="chanpin-canshu">
                            <img style="width: 90%;" src="@/../static/iamges/home/home_bg_250_80.png">
                            <div style="position: relative;top: -66px;">
                                <div style="font-size: 18px;color: #7b9d38">切片装载数量</div>
                                <div style="font-size: 14px;">单片</div>
                            </div>
                        </el-col>

                        <el-col :lg="{span:6}" :md="{span:6}" :sm="{span:6}" :xs="{span:18,offset:3}"
                                class="chanpin-canshu">
                            <img style="width: 90%;" src="@/../static/iamges/home/home_bg_250_80.png">
                            <div style="position: relative;top: -66px;">
                                <div style="font-size: 18px;color: #7b9d38">物镜</div>
                                <div style="font-size: 14px;">20X/40X</div>
                            </div>
                        </el-col>
                        <el-col :lg="{span:6}" :md="{span:6}" :sm="{span:6}" :xs="{span:18,offset:3}"
                                class="chanpin-canshu">
                            <img style="width: 90%;" src="@/../static/iamges/home/home_bg_250_80.png">
                            <div style="position: relative;top: -66px;">
                                <div style="font-size: 18px;color: #7b9d38">仪器尺寸</div>
                                <div style="font-size: 14px;">130mm*110mm*130mm</div>
                            </div>
                        </el-col>
                        <el-col :lg="{span:6}" :md="{span:6}" :sm="{span:6}" :xs="{span:18,offset:3}"
                                class="chanpin-canshu">
                            <img style="width: 90%;" src="@/../static/iamges/home/home_bg_250_80.png">
                            <div style="position: relative;top: -66px;">
                                <div style="font-size: 18px;color: #7b9d38">仪器重量</div>
                                <div style="font-size: 14px;">2.3KG</div>
                            </div>
                        </el-col>
                    </el-row>



                    <!--                    <el-row style="margin-top: 15px;border: 5px solid rgba(160,193,95,0.2);width: 93%;margin: auto">-->
                    <!--                        <iframe src="http://192.168.1.195:8080/"-->
                    <!--                                width="100%" height="300" frameborder="0"></iframe>-->
                    <!--                    </el-row>-->

                    <el-row>
                        <el-col :lg="{span:6,offset:0}" style="margin-top: 30px;margin-left: -25px">
                            <img src="@/../static/iamges/home/home_bg_title.png">
                        </el-col>

                    </el-row>
                    <el-row>
                        <el-col :lg="{span:6,offset:0}"
                                style="color: #ffffff;font-size: 20px;font-weight: 600;margin-left: -25px;position: relative; margin-top: -34px;">
                            应用场景
                        </el-col>
                    </el-row>
                    <el-row style="margin-top: 15px;">
                        <el-col :lg="{span:8} " :md="{span:8}" :sm="{span:8}" :xs="{span:16,offset:4}">
                            <el-row>
                                <img class="img-pic"
                                     style="width: 94%;height: 260px;border: 5px solid rgba(160,193,95,0.2)" fit="fill"
                                     src="@/../static/iamges/home/linchuang.jpg">
                                <img style="width: 96%;margin-top: -5px"
                                     src="@/../static/iamges/home/home_bg_340_150.png">
                                <div style="position: relative;width: 97%;margin-top: -120px;padding-left: 20px">
                                    <div class="changjing-text" style="font-size: 16px;color: #7b9d38;text-align: left">
                                        临床病理诊断
                                    </div>
                                    <p style="font-size: 14px;color: #555555;padding:0 30px;text-align: left;">
                                        利用其建立超大容量的数字病理切片库，保存珍贵的病理切片资料，解决了玻璃切片不易储存保管、易褪色、易损坏、易丢片掉片
                                        和切片检索困难等问题，并且实现了同一张切片可以再不同地点同时被很多人浏览。

                                    </p>
                                </div>
                            </el-row>
                            <el-row style="margin-top: -4px;">
                                <el-col>
                                </el-col>
                            </el-row>
                        </el-col>
                        <el-col :lg="{span:8} " :md="{span:8}" :sm="{span:8}" :xs="{span:16,offset:4}">
                            <el-row>
                                <img class="img-pic"
                                     style="width: 94%;height: 260px;border: 5px solid rgba(160,193,95,0.2)"
                                     src="@/../static/iamges/home/shiyan.jpg" fit="fill">
                                <img style="width: 96%;margin-top: -5px"
                                     src="@/../static/iamges/home/home_bg_340_150.png">
                                <div style="position: relative;width: 97%;margin-top: -120px;padding-left: 20px">
                                    <div class="changjing-text" style="font-size: 16px;color: #7b9d38;text-align: left">
                                        科学实验
                                    </div>
                                    <p style="font-size: 14px;color: #555555;padding:0 30px;text-align: left;">
                                        自带AI建模和模型解释功能，有望帮助科研工作者发现更多细胞和组织空间结构规律。</p>
                                </div>
                            </el-row>
                            <el-row style="margin-top: -4px;">
                                <el-col>
                                </el-col>
                            </el-row>
                        </el-col>
                        <el-col :lg="{span:8} " :md="{span:8}" :sm="{span:8}" :xs="{span:16,offset:4}">
                            <el-row>
                                <img class="img-pic"
                                     style="width: 94%;height: 260px;border: 5px solid rgba(160,193,95,0.2)"
                                     src="@/../static/iamges/home/teaching.jpg" object-fit="cover">
                                <img style="width: 96%;margin-top: -5px"
                                     src="@/../static/iamges/home/home_bg_340_150.png">
                                <div style="position: relative;width: 97%;margin-top: -120px;padding-left: 20px;margin-bottom: 80px">
                                    <div class="changjing-text" style="font-size: 16px;color: #7b9d38;text-align: left">
                                        课堂教学
                                    </div>
                                    <p style="font-size: 14px;color: #555555;padding:0 30px;text-align: left;">

                                        小巧便携，方便课程上用电脑展示、传阅和重点圈画讲解，性价比高，形态学教学事半功倍。</p>
                                </div>
                            </el-row>
                            <el-row style="margin-top: -4px;">
                                <el-col>
                                </el-col>
                            </el-row>
                        </el-col>





                    </el-row>


                </el-col>






            </el-row>
            <!--            <el-row>-->
            <!--                <el-col :span="16" :offset="3" style="margin-bottom: 30px">-->
            <!--                    <img style="width: 100%;" src="@/../static/iamges/home/scanPurchase.jpg" >-->
            <!--                </el-col>-->
            <!--            </el-row>-->


        </div>


    </div>
</template>
<script>
    export default {
        name: "Homepage",
        mounted() {
            this.$emit("handleSelect", "0", ["0"])
        },
        data() {
            return {
                pictureList: [
                    // require("@/../static/iamges/1.png"),
                    require("@/../static/iamges/home/bigPicture.jpg"),
                    require("@/../static/iamges/sample.png"),


                ],
                prodctIdx: 0,
                productPic: [
                    {
                        index: 0,
                        url: require("@/../static/iamges/home/scan0.jpg")
                    },
                    {
                        index: 1,
                        url: require("@/../static/iamges/home/scan1.jpg")
                    },
                    {
                        index: 2,
                        url: require("@/../static/iamges/home/scan2.jpg")
                    },


                ],
                currentUrl: require("@/../static/iamges/home/scan0.jpg"),

                contactUs: [
                    {
                        icon: require("@/../static/iamges/phone.png"),
                        text: "18121496883"
                    },
                    {
                        icon: require("@/../static/iamges/location.png"),
                        text: "江苏省苏州市工业园区仁爱路99号D2-410.411"
                    },
                    {
                        icon: require("@/../static/iamges/email.png"),
                        text: "product@medppp.com"
                    },
                    {
                        icon: require("@/../static/iamges/www.png"),
                        text: "www.turingene.com"
                    },
                ],
                activeIndex: '1',
                menuItemStyle: [
                    {backgroundColor: "#074A81", color: "#fff"},
                    {backgroundColor: "#E4E4E4", color: "#000"},
                    {backgroundColor: "#E4E4E4", color: "#000"},
                    {backgroundColor: "#E4E4E4", color: "#000"},
                    {backgroundColor: "#E4E4E4", color: "#000"}
                ],
                selectedKey: 0,

            }
        },
        methods: {
            handleSelect(key, keyPath) {
                console.log(key, keyPath);
                this.selectedKey = key;
                console.log(this.selectedKey);
                this.menuItemStyle = [
                    {backgroundColor: "#E4E4E4", color: "#000"},
                    {backgroundColor: "#E4E4E4", color: "#000"},
                    {backgroundColor: "#E4E4E4", color: "#000"},
                    {backgroundColor: "#E4E4E4", color: "#000"},
                    {backgroundColor: "#E4E4E4", color: "#000"}
                ]
                this.menuItemStyle[key] = {backgroundColor: "#074A81", color: "#fff"}
            },
            gotoDetail() {
                this.$root.$index.handleSelect("3", ["1", "3"]);
                this.$router.push({
                    path: "/productDetails"
                })
            },
            ShowProductPic2() {
                this.prodctIdx--;
                if (this.prodctIdx < 0) {
                    this.prodctIdx = 2
                }
                this.currentUrl = require("@/../static/iamges/home/scan" + this.prodctIdx + ".jpg")

            },
            gotoSlide(){
                console.log(2222)
                this.$router.push({
                    query: {
                        id: 3,
                        page:0
                    },
                    path: "/slideShow"
                })
            },
            ShowProductPic() {
                console.log(11111);
                this.prodctIdx++;
                if (this.prodctIdx > 2) {
                    this.prodctIdx = 0
                }
                this.currentUrl = require("@/../static/iamges/home/scan" + this.prodctIdx + ".jpg")


            },
        }
    }
</script>

<style scoped lang="css">
    /*<!--    屏幕宽度小于992px-->*/
    @media only screen and (max-width: 992px) {
        .homePage {
        }

        .el-carousel >>> .el-carousel__container {
            height: 100px;
        }

        .carousel-img >>> img {
            width: 100vw;
            height: 100px !important;
        }

    }

    .chanpin-pic >>> .img-home-p2 {
        margin-top: 20px;
        margin-left: 5px;
    }

    /*屏幕宽度<768px*/
    @media only screen and (max-width: 768px) {
        .el-carousel >>> .el-carousel__container {
            height: 100px;
        }

        .carousel-img >>> img {
            object-fit: fill !important;
            width: 100vw;
            height: 100px !important;
        }

    }

    /*屏幕宽度大于768px*/
    @media only screen and (min-width: 768px) {
        .el-carousel >>> .el-carousel__container {
            height: 400px;
        }

        .carousel-img >>> img {
            width: 100vw;
            height: 300px !important;
            object-fit: fill !important;
        }


    }

    /*屏幕宽度大于992px*/
    @media only screen and (min-width: 992px) {

        .el-carousel >>> .el-carousel__container {
            height: 500px;
        }

        .carousel-img >>> img {
            width: 100vw;
            height: 300px !important;
        }


        .el-carousel__item h3 {
            color: #475669;
            font-size: 14px;
            opacity: 0.75;
            line-height: 150px;
            margin: 0;
        }
    }

    @media only screen and (min-width: 1200px) {
        .chanpin-pic >>> .img-home-p2 {
            margin-top: -5px;

        }

        .carousel-img >>> img {
            width: 100vw;
            height: 500px !important;
        }
    }

    /*屏幕宽度大于1550px*/
    /*@media only screen and (min-width: 1550px) {*/
    /*    .chanpin-canshu {*/
    /*        display: flex;*/
    /*        align-items: center; */
    /*        justify-content: space-evenly;*/
    /*    }*/
    /*}*/

    /*小于768*/
    @media only screen and (max-width: 768px) {
        .chanpin-pic >>> .img-home-p2 {
            margin-top: 20px;
            margin-left: 10px;
        }

        .changjing-text {
            margin-top: 40px !important;
        }

        /*.img-pic{*/
        /*    object-fit: cover;*/
        /*}*/

    }

    /*小于480*/
    @media only screen and (max-width: 480px) {
        .chanpin-pic >>> .img-home-p2 {
            margin-top: 25px;

        }
    }

    .img-pic {
        object-fit: cover;
    }


</style>