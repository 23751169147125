<template>
    <div class="news">
        <div class="conntext" style="width: 100%;" >
            <div>
                <el-row class="hidden-md-and-down" >
                    <el-col :lg="{span:21,offset:3}"
                            :style="{backgroundImage:'url('+require('@/../static/iamges/news/news_pic.jpg')+')'}"
                            style="height: 250px;background-size: 100% 100%">
                        <el-row>
                            <el-col :lg="{span:4,offset:0}" style="margin-top: 80px;margin-left: 30px">
                                <div style="display: flex;flex-direction: column;justify-content: flex-start;width: 170px; text-align: left">
                                    <div style="color: #a0c15f;font-size: 30px;">公司要闻</div>
                                    <div style="color: #a0c15f;font-size: 18px">IMPORTANT NEWS</div>
                                    <div style="color: #ffffff;font-size: 16px;background-color: #a0c15f;
                                        width: 60px;border: 1px solid #ffffff;border-radius: 30px;text-align: center" @click="gotoDetail()"> 更多...</div>
                                </div>
                            </el-col>

                            <el-col :lg="{span:18,offset:0}" style="margin-top: 30px" >
                                <div v-for="(item , index) in articleList1" :key="index"  >
                                    <el-col :lg="{span:6}" :style="{backgroundImage:'url('+require('@/../static/iamges/news/news_detailbg1.jpg')+')'}"
                                     style="background-size: 100% 100%;width: 290px;height: 170px;margin-left: 10px">
                                        <div style="display: flex;flex-direction: column;justify-content: space-around" >
                                            <div  style="width: 80%;overflow: hidden;text-overflow:ellipsis; white-space: nowrap; text-align: center;
                                            font-weight: 600;font-size: 18px;color: #4f4f4f;margin-top: 20px;margin-left: 10px"
                                                 class="news-title">{{item.title}}
                                            </div>
                                            <div style="display: flex;justify-content: space-around;margin-top: 20px;margin-left: 10px">
                                                <div class="left-pic">
                                                    <img style="width: 100px;height: 80px" src="@/../static/iamges/home/scan2.jpg">
                                                </div>
                                                <div class="right-text" style="margin-left: 10px">
                                                    <div  class="right-text-top" style="width: 80%;word-break: break-all;font-size: 14px;color: #666666;text-align: left">
                                                       截止2020年底，我们图灵基因公众号完成了累计2100余篇原创文献的解读...
                                                    </div>
                                                    <div class="right-text-bottom" style="font-size: 14px;color: white;border: 1px solid #ffffff;
                                                        border-radius: 30px;width: 40px ;background-color: #a0c15f;padding:0px 5px;position: relative;left: 100px">
                                                        <a href="http://www.baidu.com" target="_blank" style="text-decoration: none;color: white">详情</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </el-col>


                                </div>

                            </el-col>



                        </el-row>


                    </el-col>

                </el-row>

                <el-row  class="hidden-lg-and-up">
                    <el-col :md="{span:24,offset:0}" :sm="{span:24,offset:0}"
                            style="height: 250px;">
                        <el-row >
                            <el-col :md="{span:24,offset:0}" :sm="{span:24}" :xs="{span:24}" style="margin-top: 30px;margin-left: 30px">
                                <div style="display: flex;flex-direction: column;justify-content: flex-start;width: 170px; text-align: left">
                                    <div style="color: #a0c15f;font-size: 30px;">公司要闻</div>
                                    <div style="color: #a0c15f;font-size: 18px">IMPORTANT NEWS</div>
                                    <div style="color: #ffffff;font-size: 16px;background-color: #a0c15f;
                                        width: 60px;border: 1px solid #ffffff;border-radius: 30px;text-align: center" @click="gotoDetail()"> 更多...</div>
                                </div>
                            </el-col>

                            <el-col :md="{span:24,offset:0}" :sm="{span:24,offset:0}" :xs="{span:20,offset:2}"  style="margin-top: 30px" >


                                <div v-for="(item , index) in articleList1" :key="index"  >
                                    <el-col :lg="{span:8}" :md="{span:8}" :sm="{span:8}" :xs="{span:20,offset:2}" :style="{backgroundImage:'url('+require('@/../static/iamges/news/news_detailbg1.jpg')+')'}"
                                            style="background-size: 100% 100%;width: 290px;height: 170px;margin-left: 10px">
                                        <div style="display: flex;flex-direction: column;justify-content: space-around" >
                                            <div  style="width: 80%;overflow: hidden;text-overflow:ellipsis; white-space: nowrap; text-align: center;
                                            font-weight: 600;font-size: 18px;color: #4f4f4f;margin-top: 20px;margin-left: 10px"
                                                  class="news-title">{{item.title}}
                                            </div>
                                            <div style="display: flex;justify-content: space-around;margin-top: 20px;margin-left: 10px">
                                                <div class="left-pic">
                                                    <img style="width: 100px;height: 80px" src="@/../static/iamges/home/scan2.jpg">
                                                </div>
                                                <div class="right-text" style="margin-left: 10px">
                                                    <div  class="right-text-top" style="width: 80%;word-break: break-all;font-size: 14px;color: #666666;text-align: left">
                                                        截止2020年底，我们图灵基因公众号完成了累计2100余篇原创文献的解读...
                                                    </div>
                                                    <a href="http://www.baidu.com" target="_blank" style="text-decoration: none">
                                                        <div  class="right-text-bottom" style="font-size: 14px;color: white;border: 1px solid #ffffff;
                                                        border-radius: 30px;width: 40px ;background-color: #a0c15f;padding:0px 5px;position: relative;left: 100px">
                                                           详情
                                                        </div>
                                                    </a>

                                                </div>
                                            </div>
                                        </div>

                                    </el-col>


                                </div>

                            </el-col>

                        </el-row>


                    </el-col>

                </el-row>








                <el-row style="margin-bottom: 30px" class="xs-text">
                    <el-col :lg="{span:21,offset:3}"
                            >
                        <el-row class="hangye-text">
                            <el-col :lg="{span:4,offset:0}" :md="{span:24,offset:0}" :sm="{span:24,offset:0}" :xs="{span:20,offset:2}" style="margin-top: 80px;margin-left: 30px">
                                <div style="display: flex;flex-direction: column;justify-content: flex-start;width: 170px; text-align: left">
                                    <div style="color: #ef9e1e;font-size: 30px;">行业要闻</div>
                                    <div style="color: #ef9e1e;font-size: 18px">INDUSTRY NEWS</div>
                                    <div style="color: #ffffff;font-size: 16px;background-color: #ef9e1e;
                                        width: 60px;border: 1px solid #ffffff;border-radius: 30px;text-align: center" @click="gotoDetail()"> 更多...</div>
                                </div>
                            </el-col>

                            <el-col :lg="{span:17,offset:0}"  :md="{span:24,offset:0}" :sm="{span:24,offset:0}" :xs="{span:20,offset:2}" style="margin-top: 30px;" >
                                <div v-for="(item , index) in articleList2" :key="index"  >
                                    <el-col :lg="{span:8}" :md="{span:8}" :sm="{span:8}" :xs="{span:20,offset:2}" :style="{backgroundImage:'url('+require('@/../static/iamges/news/news_detailbg1.jpg')+')'}"
                                            style="background-size: 100% 100%;width: 290px;height: 170px;margin-left: 10px;margin-top: 10px" >
                                        <div style="display: flex;flex-direction: column;justify-content: space-around" >
                                            <div  style="width: 90%;overflow: hidden;text-overflow:ellipsis; white-space: nowrap; text-align: center;
                                            font-weight: 600;font-size: 18px;color: #4f4f4f;margin-top: 20px;margin-left: 10px"
                                                  class="news-title">{{item.title}}
                                            </div>
                                            <div style="display: flex;justify-content: space-around;margin-top: 10px;margin-left: 10px">

                                                <div class="right-text" style="margin-left: 10px">
                                                    <div  class="right-text-top" style="width: 80%;word-break: break-all;font-size: 14px;color: #666666;text-align: left">
                                                        截止2020年底，我们图灵基因公众号完成了累计2100余篇原创文献的解读...
                                                    </div>
                                                    <div class="bottom" style="margin-top: 10px">
                                                        <div style="display: flex;align-items: center;justify-content: space-between" >
                                                            <div style="font-size: 14px;color: #ef9e1e;">阅读量:{{item.readCount}}</div>
                                                            <div class="right-text-bottom" style="font-size: 14px;color: white;border: 1px solid #ffffff;
                                                            border-radius: 30px;width: 40px ;background-color: #ef9e1e;padding:0px 5px;margin-right: 15px">
                                                                <a href="http://www.baidu.com" target="_blank" style="text-decoration: none;color: white">详情</a>

                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <!--                                        <div style="margin-left: 20px">-->
                                        <!--                                            <img style="width: 100%" src="@/../static/iamges/news/news_detailbg1.jpg">-->
                                        <!--                                        </div>-->
                                    </el-col>


                                </div>

                            </el-col>

                        </el-row>


                    </el-col>

                </el-row>
            </div>


        </div>
    </div>
</template>

<script>
    import {request} from "@/../src/utils/utils";

    export default {
        name: "NewsInformation",

        data() {
            return {
                req: {
                    msgId: null,
                    pageSize: 9,
                    readCount: 1,
                },
                articleList1: [],
                articleList2: [],

            }
        },

        mounted() {
            this.$emit("handleSelect", "4", ["4"])
        },
        methods: {
            cfn(){
              console.log(8888888)
            },
            getList() {
                console.log(this.req)
                request("POST", "/article/getArticleList", this.req).then(res => {
                    console.log(res);
                    this.articleList1 = res.slice(0, 3)
                    console.log(this.articleList1)
                    this.articleList2 = res.slice(3, 9)
                    console.log(this.articleList2)


                })
            },
            gotoDetail(){
                this.$router.push('NewsInformationDetail')
            }

        },
        created() {
            this.getList()

        }
    }
</script>

<style scoped>
    @media only screen and (max-width: 768px) {
        .xs-text{
            margin-top: 340px!important;
        }
        .news{
            overflow: hidden;
        }

    }

    @media only screen and (min-width: 768px) {

        .hangye-text{
            margin-top: 150px!important;
        }
        .news{
            overflow: hidden;
        }



    }

    @media only screen and (min-width: 992px) {
        .hangye-text{
            margin-top: 35px!important;
        }
        .news{
            overflow: hidden;
        }

    }




    @media screen and (min-width: 1200px)
    {
        .hangye-text{
            margin-top: 100px!important;
        }

    }
    @media screen and (min-width: 1400px){
        .hangye-text{
            margin-top: 35px!important;
        }

    }




</style>