<template>
    <div style="height:100vh;">
        <div class="Detail" style="padding-top: 60px">
            <div class="detail-title" style="color: rgb(160, 193, 95);font-size: 20px;padding-left: 10px">
                病例详情-{{mpiId}}
            </div>
        </div>

        <el-row style="font-size: 14px ;color: #666666">
            <el-col :lg="{span:14,offset:5}">
                <el-card class="box-card" shadow="hover" style="width: 100%;margin-top: 30px">

                    <!--                    <el-row>-->
                    <!--                        <el-col style="color: #666666;margin-top: 10px">-->
                    <!--                            <span style="font-weight: 600;">医院</span> &nbsp;-->
                    <!--                        </el-col>-->
                    <!--                    </el-row>-->
                    <!--                    <el-row>-->
                    <!--                        <el-col style="height: 1px;width: 100%;background-color: #d5d5d5;margin-top: 10px"></el-col>-->
                    <!--                    </el-row>-->
                    <el-row style="margin-top: 10px;width: 100%">
                        <el-col :span="12">
                            <span style="font-weight: 600;">性别 </span>&nbsp;{{list.sex==0?"男" :"女"}}
                        </el-col>
                        <el-col :span="12">
                            <span style="font-weight: 600;"> 年龄</span> &nbsp;{{list.age==null?"" :list.age}}岁
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col style="height: 1px;width: 100%;background-color: #d5d5d5;margin-top: 10px"></el-col>
                    </el-row>

                    <el-row style="margin-top: 10px;width: 100%" class="text-des">
                        <el-col :lg="{span:24}">
                            <el-row>
                                <el-col :lg="{span:2}" :md="{span:2}" :sm="{span:2}" :xs="{span:4}"
                                        style="font-weight: 600;">临床资料
                                </el-col>
                                <el-col style="font-size: 13px"
                                        :lg="{span:20}" :md="{span:20}" :sm="{span:20}" :xs="{span:18}">{{list.title}}
                                </el-col>
                            </el-row>

                            <el-row style="margin-top: 10px">
                                <el-col :lg="{span:2}" :md="{span:2}" :sm="{span:2}" :xs="{span:4}"
                                        style="font-weight: 600;">投稿人
                                </el-col>
                                <el-col style="font-size: 13px;"
                                        :lg="{span:20,offset:0}" :md="{span:20,offset:0}" :sm="{span:20,offset:0}"
                                        :xs="{span:20,offset:0}">{{list.contributor==null? "":list.contributor}}
                                </el-col>
                            </el-row>
                            <el-row style="margin-top: 10px">
                                <el-col :lg="{span:2}" :md="{span:2}" :sm="{span:2}" :xs="{span:4}"
                                        style="font-weight: 600;">机构
                                </el-col>
                                <el-col style="font-size: 13px;"
                                        :lg="{span:20,offset:0}" :md="{span:20,offset:0}" :sm="{span:20,offset:0}"
                                        :xs="{span:20,offset:0}">{{list.unit==null? "":list.unit}}
                                </el-col>
                            </el-row>
                        </el-col>
                        <el-col :span="20" :offset="2">
                            <P v-html="list.description" style="margin: 0px">
                                <!--                                {{list.description}}-->

                            </P>
                            <span v-if="list.keyword!=null" style="display: block;margin-top: 10px">
                                        <br/>关键字：{{list.keyword}}
                                    </span>
                        </el-col>


                    </el-row>

                    <el-row>
                        <el-col style="height: 1px;width: 100%;background-color: #d5d5d5;margin-top: 10px"></el-col>
                    </el-row>

                    <el-row style="margin-top: 10px" class="text-des">
                        <el-col :lg="{span:2}">
                            <span style="font-weight: 600;">数字切片 </span>
                        </el-col>
                        <el-col :lg="{span:22}" :md="{span:20,offset:2}" :sm="{span:20,offset:2}"
                                :xs="{span:20,offset:4}"
                                style="display: flex;flex-wrap: wrap;justify-content: flex-start ">
                            <div v-for="(item , index) in list.slideThumb " :key="index">
                                <a :href="'https://scan.turingene.com/static/index.html?slide='+item.viewId"
                                   target="_blank">
                                    <el-col :lg="{span:5}" :md="{span:5}" :sm="{span:5}" :xs="{span:10,offset:6}"
                                            :style="{backgroundImage:'url('+require('@/../static/iamges/news/news_detailbg1.jpg')+')'}"
                                            style="background-size: 100% 100%;margin-top: 5px;margin-left: 6px;background: no-repeat;width: 98%">
                                        <div class="right-text">
                                            <div class="right-text-top"
                                                 style="width: 185px;border: 2px solid  #a0c15f;height: 105px;padding: 5px;text-align:center;font-size: 12px">
                                                <img class="img-thumb" style="height: 60px;width: auto"
                                                     :src="item.thumb">
                                                <div style="text-align: center">{{item.name}}</div>
                                            </div>
                                        </div>
                                    </el-col>
                                </a>

                            </div>


                        </el-col>

                    </el-row>


                </el-card>
            </el-col>

        </el-row>
        <div style="text-align: center;color: #a0c15f;margin-top: 20px">
            <div style="color: #a0c15f">图灵基因切片云共享</div>
        </div>
        <div class="footer">版权所有 : 苏州迪美格智能科技有限公司 &nbsp;<span><a href="http://beian.miit.gov.cn" target="_blank"
                                                                rel="nofollow"
                                                                style="color: #666666;white-space: nowrap;text-decoration: none">苏ICP备17054065号-4</a></span>
        </div>
    </div>
</template>

<script>
    import {request} from "@/utils/utils";

    export default {
        name: "slideShowDetail",
        data() {
            return {
                mpiId: null,
                list: {
                    title: null,
                    sex: null,
                    unit: null,
                    age: null,
                    contributor: null,
                    keyword: null,
                    description: null,
                    siId: null,
                    slideThumb: [],



                },
                title: '<p>' +
                    '一、临床病史：<br/>' +
                    '患者男性，66岁，因“晕厥1次”于2022年11月18日入院。<br/>' +
                    '当时床边心电图提示心室扑动，予电除颤后意识恢复。为进一步检查明确病因转诊至我院。<br/>' +
                    '体格检查：血压98/62mmHg，神清，精神可，口唇无紫绀，双侧颈静脉无明显充盈，双肺未闻及干湿啰音。心率82次/分，心律齐，心脏各瓣膜未闻及病理性杂音，双下肢无水肿。查血常规、凝血系列、血生化、血电解质、甲状腺功能、肿瘤标志物、C反应蛋白和脑钠肽均未见明显异常。<br/>' +
                    '二、心电图、心脏影像学<br/>' +
                    '心电图提示完全性右束支传导阻滞。\n' +
                    '超声心动图提示：左室肥厚，左室后壁基底段、侧壁基底段心肌变薄，较薄处约0.46cm，活动减弱，左心及右房增大，左心功能减弱，左室总纵向应变（LV GLS）=-10.7%,右室纵向收缩功能减低，二尖瓣中等量反流。<br/>' +
                    '三、血生化：\n' +
                    '血浆GLA水平：0.91（参考范围2.40-17.65）。血浆Lyso-GL-3水平： 4.13（参考值<1.11）。基因检测结果提示突变位点为：IVS4+919G>A。<br/>' +
                    '四、心肌活检：<br/>' +
                    '  心肌活检钳经右侧颈内静脉送入右心室，于室间隔右侧钳取心肌组织4块，送病理，行HE、免疫组织和特殊染色检查。<br/>' +
                    'HE组织学特点：1、心肌细胞大小不一，间隙明显增宽，部分心肌细胞空泡状改变，空泡大小不一，内见颗粒状及絮状物。2、心肌细胞肥大变性。3、间质散在少许淋巴细胞浸润。<br/>' +
                    '免疫组化：CD3、CD4、CD8阳性；CD20、CD68阴性。<br/>' +
                    '特殊染色：甲苯胺蓝染色空泡内可见深蓝色颗粒状物质。<br/>' +
                    '电镜检查：肌原纤维间大量髓鞘样结构和空泡样结构聚集，间质胶质纤维片状沉积。<br/>' +
                    '五：基因检测：检测到Fabry病相关GLA中1个半合子变异。<br/>' +
                    '六：临床病理诊断：Fabry病心脏变异型。\n</p>'
            }
        },
        methods: {
            getDetail() {
                let _this = this
                let d = {
                    mpiId: _this.mpiId
                }
                request("POST", "/getPathologyDetails", d).then(res => {
                    console.log(res)
                    _this.list.title = res.title
                    _this.list.sex = res.sex
                    _this.list.unit = res.unit
                    _this.list.age = res.age
                    _this.list.contributor = res.contributor
                    _this.list.keyword = res.keyword
                    _this.list.siId = res.siId
                    _this.list.description = res.description
                    _this.list.slideThumb = res.info
                    _this.list.keyword = res.keyword


                })
            },


        },
        created() {

            console.log(this.$route.query.id, this.$route.query.page);
            if (this.$route.query.page == 0) {
                localStorage.setItem("articleId", this.$route.query.id);
                this.mpiId = localStorage.getItem("articleId");
            } else {
                this.mpiId = localStorage.getItem("articleId");
            }

            console.log(this.mpiId);
            this.getDetail();

        },
    }
</script>

<style scoped>
    /*屏幕大于1200*/
    @media only screen and (min-width: 1200px) {
        .Detail {
            margin: auto;
            width: 60vw;
        }
    }

    /*屏幕在992-1200*/
    @media screen and (min-width: 992px) and (max-width: 1200px) {
        .Detail {
            margin: auto;
            width: 60vw;
        }
    }


    /*    屏幕小于992*/
    @media only screen and (max-width: 992px) {
        .footer {
            font-size: 10px !important;
        }
    }

    .text-des:hover {
        background-color: #f3f3f3 !important;
    }

    #app {
        width: 100%;
        margin: 0;
        padding: 0;
        background-color: #666666;
    }

    .footer {
        display: flex;
        justify-content: center;
        background-color: #fdfdfd;
        height: 50px;
        line-height: 50px;
        color: #666666;
        font-weight: 500;
        font-size: 13px;
        margin-top: 20px;
        /*position: fixed;*/
        /*width: 100vw;*/
        /*bottom: 0%;*/
    }

    .img-thumb {
        object-fit: contain;
    }

    p  {
    margin: 0px!important;
    }

</style>
