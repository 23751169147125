import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import index from '../views/index/index.vue'
import AboutUs from '../views/index/aboutUs/AboutUs.vue'
import NewsInformation from '../views/index/newsInfomation/NewsInformation.vue'
import recruit from '../views/index/recruit/recruit.vue'
import Homepage from '../views/index/homePage/HomePage.vue'
import ProductDetails from '../views/index/productDetails/productDetails.vue'
import ScanList from '../views/index/scanList/scanList.vue'
 import NewsInformationDetail from '../views/index/NewsInformationDetail/NewsInformationDetail.vue'
 import Login from '../views/index/Login/Login.vue'
 import editNews from '../views/index/editNews/editNews.vue'
 import slideShow from '../views/slideShow/slideShow.vue'
 import slideShowDetail from '../views/slideShowDetail/slideShowDetail.vue'

Vue.use(VueRouter)

const routes:Array<RouteConfig> = [
  {
    path: '/index',
    redirect:'/homepage'
  },
  {
    path: '/',
    redirect:'/homepage'
  },
  {
    path: '/index',
    name: 'index',
    meta:{
      title:'首页'
    },
    component:index,
    children: [
      {
        path: '/aboutUs',
        name: 'AboutUs',
        component:AboutUs,
        meta:{
          title:"关于我们"
        },
      },
      {
        path: '/newsInformation',
        name: 'NewsInformation',
        component:NewsInformation,

      }, {
        path: '/NewsInformationDetail',
        name: 'NewsInformationDetail',
        component:NewsInformationDetail,

      },
      {
        path: '/productDetails',
        name: 'ProductDetails',
        component:ProductDetails
      },
      {
        path: '/scanList',
        name: 'ScanList',
        component:ScanList
      },
      {
        path: '/recruit',
        name: 'recruit',
        component:recruit
      },
      {
        path: '/homepage',
        name: 'Homepage',
        component:Homepage
      },

      // {
      //   path: '/contactUs',
      //   name: 'contactUs',
      //   component:contactUs
      // },
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component:Login
  },
{
    path: '/editNews',
    name: 'editNews',
    component:editNews
  },{
    path: '/slideShow',
    name: 'slideShow',
    component:slideShow
  },{
    path: '/slideShowDetail',
    name: 'slideShowDetail',
    component:slideShowDetail
  },

]

const router = new VueRouter({
  routes,
  mode:"hash"
})


export default router
