import axios from "axios"
import {Message} from 'element-ui';

// import router from "@/../router/router";


function postRequest(url: string, data: object) {
    return request("post", url, data)
}

function GetRequest(url: string, data: object) {
    return request("get", url, data)
}

function request(method: string, url: string, data: object) {
    method = method.toLowerCase()

    return axios({
        method: method,
        //获取新闻
        // url: "http://192.168.1.172:1111" + url,

        //获取切片
        url: 'https://scan.turingene.com/server' + url,
        // url:'http://192.168.1.162:10009'+url,
        headers: {
            'Content-Type': 'application/json',
        },
        data: method == 'post' ? data : null,
        params: method == 'get' ? data : null
    }).then(res => {
        console.log("工具里的res", res);
        //请求成功以后走的路线
        if (res.status === 200) {
            if (res.data.code == 0 || res.data.code == 31303) {
                return res.data.data
            } else {
                // if (res.data.code.indexOf("AT") !== -1) {
                //     // router.push("/login")
                // }
                Message.error("请求失败，错误代码：" + res.data.code + " 错误信息：" + res.data.msg);
                return false
            }
        }
    }).catch(res => {
        //请求出错走的路线
        console.log(res);
        Message.error("路线失败:" + res.response.status + "  -  " + res.message)
    })
}


export {
    request,
    postRequest,
    GetRequest


}
