<template>
    <div class="newsDetail">
        <div v-loading="loading">
            <div class="detail-content" @click="openDraw(item)"
                 v-for="(item, index) in articleListDetail"
                 :key="index"
                 style="width: 60vw;margin: auto;border: 2px solid #a0c15f;
                     border-radius: 10px;margin-top: 30px;padding: 20px 20px;background-color: #ffffff"
                 ref="articleListElement"
            >


                <div style="display: flex;align-items: center;">
                    <div
                            class="news-title;"
                            style="float: left;width: 80%;overflow: hidden;text-overflow:ellipsis; white-space: nowrap;text-align: left;
                        color: #666666;font-size: 18px"
                    >
                        {{ item.title }}
                    </div>
                    <div class="news-date" style="float: right;width: 20%;font-size: 14px;color:  #666666">
                        阅读量:{{item.readCount}}
                    </div>
                </div>


            </div>
            <div v-if="!noMore" style="margin-top: 30px">
                <el-button
                        v-show="isLoding"
                        type="text"
                        style="display: flex; margin: 0 auto; color: #000"
                ><i class="el-icon-loading"></i
                ></el-button>
            </div>
            <div style="margin-top: 20px;margin-bottom: 20px">
                <el-button
                        type="text"
                        v-show="finishLoding"
                        style="display: flex; margin: 0 auto; color:#929292;font-weight: 600"
                >没有更多了
                </el-button>
            </div>


            <el-drawer

                    :before-close="handleClose"
                    :visible.sync="dialog"
                    direction="ltr"
                    custom-class="demo-drawer"
                    ref="drawer"
                    size="40%"
            >
                <div class="demo-drawer__content" style="width: 600px">
                    <el-form :model="article" ref="articleForm" label-width="120px" :rules="rules">
                        <el-form-item label="文章发出时间 " required prop="refDate">
                            <el-input style="width: 90%" v-model="article.refDate"></el-input>
                        </el-form-item>

                        <el-form-item label="文章标题" required prop="title">
                            <el-input style="width: 90%" v-model="article.title"></el-input>
                        </el-form-item>

                        <el-form-item label="文章概述" required prop="overview">
                            <el-input style="width: 90%" v-model="article.overview" type="textarea"></el-input>
                        </el-form-item>

                        <el-form-item label="文章访问链接 " required prop="url">
                            <el-input style="width: 90%" v-model="article.url"></el-input>
                        </el-form-item>

<!--                        <el-upload-->
<!--                                class="upload-demo"-->
<!--                                action="http://localhost:9527/src/icons/"-->
<!--                                :on-preview="handlePreview"-->
<!--                                :on-remove="handleRemove"-->
<!--                                :file-list="fileList"-->
<!--                                :limit="1"-->
<!--                                list-type="picture"-->
<!--                                style="width: 600px; margin-left: 30px"-->
<!--                        >-->
<!--                            <el-button size="small" type="primary">点击上传</el-button>-->
<!--                            <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>-->
<!--                            <div slot="tip" class="el-upload__tip">最多仅能上传4张图片</div>-->
<!--                        </el-upload>-->
                        <!--                        </el-form-item>-->


                        <el-form-item style="margin-top: 50px">
                            <el-button type="primary" @click="submitForm">提交</el-button>
                        </el-form-item>
                    </el-form>


                </div>
            </el-drawer>


        </div>
    </div>
</template>

<script>
    import {request} from "@/utils/utils";

    export default {
        name: "NewsInformationDetail",
        data() {
            return {
                req: {
                    msgId: null,
                    pageSize: 10,
                    readCount: 1,
                },
                articleListDetail: [],
                latestMsgId: null,
                noMore: false,
                loading: true, // 组件loading的展示
                isLoding: false, // 加载中，loading图标
                finishLoding: false, // 加载完成，显示已经没有更多了
                lastScrollTop: 0,

                dialog: false,
                article: {

                    refDate: '',
                    title: '',
                    overview: '',
                    url: ''
                },
                fileList: [],

                rules: {
                    refDate: [{required: true, message: "请输入文章发出时间", trigger: "blur"}],
                    title: [{required: true, message: "请输入文章标题", trigger: "blur"}],
                    overview: [{required: true, message: "请输入文章概述", trigger: "blur"}],
                    url: [{required: true, message: "请输入文章访问链接", trigger: "blur"}],
                },
                msgId: null,


            };
        },

        methods: {
            getList() {
                let _this = this;
                _this.loading = true;
                _this.isLoding = true;

                request("POST", "/article/getArticleList", _this.req).then((res) => {
                    if (_this.req.msgId == null || _this.req.msgId == undefined) {
                        _this.articleListDetail = res;
                    } else {
                        _this.articleListDetail = [..._this.articleListDetail, ...res];
                    }
                    if (res.length < 10) {
                        _this.noMore = true;
                        _this.finishLoding = true;
                        window.removeEventListener("scroll", _this.throttle(), false);
                        console.log(_this.finishLoding)
                    }
                    _this.latestMsgId = _this.articleListDetail[
                    _this.articleListDetail.length - 1
                        ].msgId;
                }).finally(() => {
                    _this.loading = false;
                    _this.isLoding = false;
                });
            },

            // 滚动触底加载
            scrollFn() {
                let clientHeight = document.documentElement.clientHeight - 18; //可视区域
                let scrollHeight = document.body.scrollHeight; // 滚动文档高度
                let scrollTop = parseInt(document.documentElement.scrollTop); // 已滚动的高度
                let height = 300;
                if (scrollTop + clientHeight >= scrollHeight - height && scrollHeight !== 0) {
                    this.req.msgId = this.latestMsgId;
                    this.getList();
                }
            },
            handleScroll() {
                let currentScrollTop = window.scrollY || window.pageYOffset;

                if (currentScrollTop > this.lastScrollTop) {
                    // 向下滚动
                    this.scrollDown();
                } else if (currentScrollTop < this.lastScrollTop) {
                    // 向上滚动
                    this.scrollUp();
                }

                this.lastScrollTop = currentScrollTop;
            },
            scrollUp() {
                // 处理向上滚动的逻辑
            },
            scrollDown() {
                // 处理向下滚动的逻辑
            },
            throttle(fn, wait) {
                // 实现节流函数
                let timer = null;
                return function () {
                    const context = this;
                    const args = arguments;
                    if (!timer) {
                        timer = setTimeout(function () {
                            fn.apply(context, args);
                            timer = null;
                        }, wait);
                    }
                };
            },
            openDraw(item) {
                this.article = {
                    title: null,
                    refDate: null,
                    url: null,
                    overflow: null
                }
                console.log(item)
                this.dialog = true
                this.article.title = item.title;
                this.article.refDate = item.refDate;
                this.article.url = item.url;
                this.article.overview = item.overview
                this.msgId = item.msgId
            },
            handleClose() {
                this.dialog = false
            },
            handleRemove(file, fileList) {
                console.log(file, fileList);
            },
            handlePreview(file) {
                console.log(file);


            },
            submitForm() {
                let _this = this
                _this.$refs.articleForm.validate((valid) => {
                    if (valid) {
                        // 表单校验通过，处理表单提交逻辑
                        console.log("表单已提交：", this.article);
                        let data = {
                            msgId: _this.msgId,
                            refDate: _this.article.refDate,
                            title: _this.article.title,
                            overview: _this.article.overview,
                            url: _this.article.url

                        }
                        request("POST", "/article/updateArticle", data).then(res => {
                            console.log(res)
                            this.$message({
                                message: '编辑成功',
                                type: 'success'
                            });
                            _this.dialog = false
                            _this.getList()

                        })
                        // 可以在此处执行其他操作，比如发送数据到服务器
                    } else {
                        // 表单校验不通过
                        console.error("表单校验未通过");
                    }
                });
            },
        },
        created() {
            this.getList();
            window.addEventListener("scroll", this.throttle(this.scrollFn, 1000));
        },
    };
</script>

<style scoped>
    el-drawer__container >>> .el-drawer ltr demo-drawer {
        width: 50%;
    }

</style>
