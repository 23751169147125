<template>
  <div style="height:100%;">
    <div class="slideShow">
      <div class="slideShow-top">
        <el-row style="font-size: 15px;" class="top-title">
          <el-col :lg={span:15,offset:0} :md="{span:24,offset:0}" :sm="{span:24}" :xs="{span:24}">
            <img class="top-pic-img" src="@/../static/iamges/slide/banner.jpg">
          </el-col>
          <el-col :lg={span:8,offset:0} :md="{span:24}" :sm="{span:24}" :xs="{span:24}"
                  style="text-align: left;margin-top: 30px">
            <el-row>
              <el-col :lg="{span:24}" :md="{span:24}">
                <el-row v-if="mettingInfo.meetingId==1||mettingInfo.meetingId==2">
                  <el-col :lg="{span:6,offset:1}" :sm="{span:4,offset:6}" :xs="{span:4,offset:3}"
                          style="font-weight: 600">
                    会议类型
                  </el-col>
                  <el-col :lg="{span:6,offset:0}" :sm="{span:6}" :xs="{span:8,offset:1}">
                    {{ mettingInfo.meetingId == 1 ? "会前会" : "读片会" }}
                  </el-col>
                </el-row>

                <el-row v-if="mettingInfo.meetingId==3">
                  <el-col :lg="{span:6,offset:1}" :sm="{span:4,offset:6}" :xs="{span:4,offset:3}"
                          style="font-weight: 600">
                    切片总览
                  </el-col>
                </el-row>
              </el-col>
            </el-row>

            <el-row>
              <el-col :lg="{span:24}" :md="{span:24}">
                <el-row style="margin: 15px 0px">
                  <el-col :lg="{span:6,offset:1}" :md="{span:4,offset:6}" :sm="{span:4,offset:6}"
                          :xs="{span:4,offset:3}" style="font-weight: 600">
                    会议时间
                  </el-col>
                  <el-col :lg="{span:15}" :md="{span:8}" :sm="{span:8}" :xs="{span:12,offset:1}">
                    {{ mettingInfo.startDay }}至{{ mettingInfo.endDay }}
                  </el-col>
                </el-row>
              </el-col>
            </el-row>


            <el-row>
              <el-col :lg="{span:24}">
                <el-row>
                  <el-col :lg="{span:6,offset:1}" :sm="{span:4,offset:6}" :xs="{span:4,offset:3}"
                          style="font-weight: 600">
                    会议地点
                  </el-col>
                  <el-col :lg="{span:15}" :sm="{span:6}" :xs="{span:12,offset:1}">
                    {{ mettingInfo.meetingVenue }}
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </div>

      <div class="main" style="margin-top: 40px;height: 80%;">
        <el-row style="display: flex;align-items: center">
          <el-col :lg="{span:4,offset:0}" :md="{span:4,offset:0}" :sm="{span:4,offset:2}" :xs="{span:4,offset:1}"
                  style="color: rgb(160, 193, 95);font-size: 20px;" class="show-text">切片展示
          </el-col>
          <el-col :lg="{span:9,offset:11}" :md="{span:9,offset:6}" :sm="{span:9,offset:6}" :xs="{span:18,offset:1}"
                  style="display: flex;align-items: center">
            <keep-alive>
              <el-input v-model="keyWord" placeholder="可输入病例名 提供者 医院 切片号"
                        style="width: 200px;font-size: 12px;padding-right: 5px" clearable
                        @clear="clearFn()"></el-input>
            </keep-alive>
            <el-button round plain @click="gotoSearch()">搜索</el-button>
          </el-col>
        </el-row>


        <el-row style="margin-bottom: 30px" class="xs-text">
          <el-col :lg="{span:24}">
            <el-row :gutter="70" class="hangye-text">
              <el-col :lg="{span:24,offset:0}" :md="{span:24,offset:0}" :sm="{span:20,offset:2}"
                      :xs="{span:20,offset:3}" style="margin-top: 30px;">
                <div v-for="(item , index) in List " :key="index" @click="gotoDetail(item.mpiId)">
                  <el-col :lg="{span:8}" :md="{span:8}" :sm="{span:8}" :xs="{span:20,offset:3}"
                          :style="{backgroundImage:'url('+require('@/../static/iamges/news/news_detailbg1.jpg')+')'}"
                          style="background-size: 100% 100%;width: 280px;height: 165px;margin-left: 10px;margin-top: 10px;">
                    <div style="display: flex;flex-direction: column;justify-content: space-around">
                      <div style="width: 90%;overflow: hidden;text-overflow:ellipsis; white-space: nowrap; text-align: left;
                                            font-weight: 600;font-size: 15px;color: #4f4f4f;margin-top: 10px;margin-left: 10px"
                           class="news-title">{{ item.mpiId }}-{{ item.title }}
                      </div>
                      <div style="display: flex; flex-direction: column; justify-content: space-between; height: 100%;">
                        <div class="right-text" style="width: 100%; padding: 10px; box-sizing: border-box;">
                          <div class="right-text-top"
                               style="width: 185px;border:2px solid #a0c15f;height:65px;text-align: center;padding:5px 0;position: relative">
                            <span style="top: 5px; left: 12px;color: #a0c15f;position: absolute">{{
                                item.number
                              }}</span>
                            <img class="img-thumb" style="max-height: 100%; width: auto; margin: auto;"
                                 :src="item.thumb">
                          </div>
                          <!-- 新增itm.unit行 -->
                          <div
                              style="font-size: 14px;color: #a0c15f;text-align: center; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
                            {{ item.unit }}
                          </div>
                          <div class="bottom" style="margin-top: 2px">
                            <div style="display: flex;align-items: center;justify-content: space-between">
                              <div style="font-size: 14px;color: #a0c15f;">
                                浏览量:{{ item.readNum == null ? 0 : item.readNum }}
                              </div>
                              <div class="right-text-bottom" style="font-size: 14px;color: white;border: 1px solid #ffffff;
                                                            border-radius: 30px;width: 40px ;background-color: #a0c15f;padding:0px 5px;margin-right: 15px">
                                <div style="color: white;margin-left: 5px">详情</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </el-col>
                </div>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </div>
      <div style="text-align: center;width: 65%;margin-top: 40px;margin-left: 40px;padding-bottom: 20px">
        <el-pagination :pager-count="3" :page-size="16" layout="total, prev, pager, next" :total="total" background
                       :current-page="currentPage"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"></el-pagination>
      </div>

      <el-row style="color: #a0c15f;">
        <el-col :lg="{span:5,offset:7}" :md="{span:5,offset:6}" :sm="{span:5,offset:6}" :xs="{span:14,offset:7}">
          图灵基因切片云共享
        </el-col>
      </el-row>
    </div>
    <div class="footer">版权所有 : 苏州迪美格智能科技有限公司 &nbsp;
      <span>
        <a href="http://beian.miit.gov.cn" target="_blank" rel="nofollow"
           style="color: #666666;white-space: nowrap;text-decoration: none">苏ICP备17054065号-4</a></span>
    </div>
  </div>
</template>

<script>
import {request} from "@/utils/utils";

export default {
  name: "slideShow",
  watch: {
    "$route"() {
      console.log(this.$route.query.id);
      this.req.meetingId = this.$route.query.id;
      this.meetingId = this.$route.query.id;
      this.getList();
      this.getMeeting()
      // this.handleIdChange(this.$route.query.id)
    }
  },
  data() {
    return {
      mettingInfo: {
        meetingName: null,
        organizer: null,
        meetingId: null,
        startDay: null,
        endDay: null,
        meetingVenue: null,
        meetingBrief: null,
        meetingType: null,
        meetingPicture: null,
      },
      meetingId: "",
      keyWord: "",
      List: [],

      req: {
        pageNum: 1,
        pageSize: 16,
        search: null,
        contributor: null,
        unit: null,
        meetingId: null,
      },
      total: null,
      currentPage: 1

    }
  },
  methods: {
    // handleIdChange(id){
    //    console.log(id)
    //      this.req.meetingId =this.$route.query.id
    //      this.getList()
    //
    //
    // },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.req.pageNum = val
      this.currentPage = val
      this.getList()
    },
    getList() {
      let _this = this
      request("POST", "/getMeetingSlide", this.req).then(res => {
        if (res != null) {
          _this.total = res.totalSize;
          _this.List = res.slideList
        } else {
          _this.total = 0;
          _this.List = []
        }
      })
    },
    gotoSearch() {
      let _this = this
      _this.req.search = _this.keyWord
      _this.req.pageNum = 1
      _this.getList()
    },
    gotoDetail(mpiId) {
      localStorage.setItem('currentPage', this.currentPage)
      this.$router.push({
        query: {
          id: mpiId,
          page: 0
        },
        path: "/slideShowDetail"
      })
    },
    getMeeting() {
      let _this = this
      let d = {
        meetingId: _this.meetingId
      }
      console.log(d)

      request("GET", "/getMeetingInfo", d).then(res => {
        console.log(res, "会议")
        _this.mettingInfo.endDay = res.endDay
        _this.mettingInfo.meetingBrief = res.meetingBrief
        _this.mettingInfo.meetingId = res.meetingId
        _this.mettingInfo.meetingName = res.meetingName
        _this.mettingInfo.meetingPicture = res.meetingPicture
        _this.mettingInfo.meetingType = res.meetingType
        _this.mettingInfo.meetingVenue = res.meetingVenue
        _this.mettingInfo.organizer = res.organizer
        _this.mettingInfo.startDay = res.startDay
      })
    },
    clearFn() {
      this.req.pageNum = 1
      this.req.pageSize = 16
      this.req.search = null
      this.req.contributor = null
      this.req.unit = null
      this.req.meetingId = this.meetingId
      this.getList()
    },
  },
  beforeCreate() {
    console.log(this)
  },
  created() {
    console.log(this)
    console.log(this.$route.query.id)
    this.meetingId = this.$route.query.id
    this.req.meetingId = this.$route.query.id
    this.getMeeting()
    let currentPage = localStorage.getItem("currentPage");
    console.log("currentPage", currentPage)
    if (currentPage != null && currentPage != undefined) {
      this.currentPage = currentPage
      this.req.pageNum = currentPage
      localStorage.removeItem('currentPage');
    } else {
      this.currentPage = 1
      this.req.pageNum = 1
    }
    this.getList()
  },
  beforeDestroy() {
    console.log(this)
  }
}
</script>

<style scoped lang="css">

.slideShow-top {
  padding-top: 50px;
}

/*屏幕大于1200*/
@media only screen and (min-width: 1200px) {
  .slideShow {
    margin: auto;
    width: 70vw;
  }

  .top-pic {
    width: 60%;
  }

  .top-pic-img {
    width: 100%;
  }

  /*.slideShow-top {*/
  /*    display: flex;*/
  /*    align-items: center;*/
  /*}*/
  .top-text {
    width: 30%;
  }

  .footer {
    margin-left: -210px;
  }
}

/*屏幕在992-1200*/
@media screen and (min-width: 992px) and (max-width: 1200px) {
  .footer {
    margin-left: -60px;
  }

  .slideShow {
    margin: auto;
    width: 70vw;
  }

  .top-pic-img {
    width: 100%;
  }

  .top-pic {
    width: 60%;
  }

  .slideShow-top {
    display: flex;
    align-items: center;

  }

  .top-text {
    width: 30%;
  }
}

/*    屏幕小于992*/
@media only screen and (max-width: 992px) {
  .slideShow {
    margin: auto;

  }

  .top-pic-img {
    width: 100%;

  }

  .show-text {
    font-size: 15px !important;
  }

  .footer {
    font-size: 10px !important;
  }
}

.el-button.is-round {
  border-radius: 20px;
  padding: 6px 16px;
  background: rgba(152, 181, 95, 0.8);
  color: white;
  margin-left: 5px;
  border: 1px solid #a0c15f;
}

.el-button.is-round:hover {
  border-radius: 20px;
  padding: 6px 16px;
  background: rgba(152, 181, 95, 0.8);
  color: white;
  margin-left: 5px;
  border: 1px solid #a0c15f;
}

.el-button.is-round:focus {
  border-radius: 20px;
  padding: 6px 16px;
  background: rgba(152, 181, 95, 0.8);
  color: white;
  margin-left: 5px;
  border: 1px solid #a0c15f;
}

.el-button.is-round:active {
  border-radius: 20px;
  padding: 6px 16px;
  background: rgba(152, 181, 95, 0.8);
  color: white;
  margin-left: 5px;
  border: 1px solid #a0c15f;
}

.el-input--suffix >>> .el-input__inner {
  padding: 5px !important;
  border: 1px solid #a0c15f;
}

.el-input--suffix >>> .el-input__inner:focus {
  border: 1px solid #a0c15f;
}

.footer {
  display: flex;
  justify-content: center;
  /*background-color: #e6e6e6;*/
  height: 50px;
  line-height: 50px;
  color: #666666;
  font-weight: 500;
  font-size: 13px;
  margin-top: 20px;
  background-color: #fdfdfd;


}

.img-thumb {
  object-fit: contain;
}


</style>
