<template>
   <div>
       scanList
   </div>
</template>

<script>
    export default {
        name: "scanList"
    }
</script>

<style scoped>

</style>