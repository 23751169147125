<template>
    <div class="newsDetail">
        <el-row>
            <el-col :lg="{span:6,offset:6}" :md="{span:6,offset:6}" :sm="{span:12,offset:6}" :xs="{offset:16,offset:4}">
                <div style="display: flex;align-items: center;margin-top: 30px;">

                    <el-input
                            style="width: 200px;text-align: center;margin-left: 5px;margin-left: -20px;border: 1px solid  #a0c15f;border-radius: 4px"
                            placeholder="请输入关键字"
                            v-model="req.title" clearable/>
                    <div style="color: #666666;margin-left: 5px;border: 1px solid #ffffff;background-color: #a0c15f;border-radius: 10px;
                       font-size: 14px;width: 80px;width: 45px; padding: 5px 5px;color: white;" @click="search()">搜索
                    </div>
                </div>
            </el-col>
        </el-row>

        <div v-loading="loading">
            <div class="detail-content"
                 v-for="(item, index) in articleListDetail"
                 :key="index"
                 style="width: 60vw;margin: auto;border: 2px solid #a0c15f;
                     border-radius: 10px;margin-top: 30px;padding: 20px 20px;background-color: #ffffff"
                 ref="articleListElement"
            >

                <a href="http://www.baidu.com" target="_blank" style="text-decoration: none;">
                    <div style="display: flex;align-items: center;">
                        <div
                                class="news-title;"
                                style="float: left;width: 80%;overflow: hidden;text-overflow:ellipsis; white-space: nowrap;text-align: left;
                        color: #666666;font-size: 18px"
                        >
                            {{ item.title }}
                        </div>
                        <div class="news-date" style="float: right;width: 20%;font-size: 14px;color:  #666666">
                            阅读量:{{item.readCount}}
                        </div>
                    </div>

                </a>


            </div>
            <div v-if="!noMore" style="margin-top: 30px">
                <el-button
                        v-show="isLoding"
                        type="text"
                        style="display: flex; margin: 0 auto; color: #000"
                ><i class="el-icon-loading"></i
                ></el-button>
            </div>
            <div style="margin-top: 20px;margin-bottom: 20px">
                <el-button
                        type="text"
                        v-show="finishLoding"
                        style="display: flex; margin: 0 auto; color:#929292;font-weight: 600"
                >没有更多了
                </el-button>
            </div>

        </div>
    </div>
</template>

<script>
    import {request} from "@/utils/utils";

    export default {
        name: "NewsInformationDetail",
        data() {
            return {
                req: {
                    msgId: null,
                    pageSize: 10,
                    readCount: 1,
                    title: null,

                },

                articleListDetail: [],
                latestMsgId: null,
                noMore: false,
                loading: true, // 组件loading的展示
                isLoding: false, // 加载中，loading图标
                finishLoding: false, // 加载完成，显示已经没有更多了
            };
        },

        methods: {
            getList() {
                let _this = this;
                _this.loading = true;
                _this.isLoding = true;
                _this.noMore = false;
                _this.finishLoding = false;


                request("POST", "/article/getArticleList", _this.req).then((res) => {
                    if (_this.req.msgId == null || _this.req.msgId == undefined) {
                        _this.articleListDetail = res;
                    } else {
                        _this.articleListDetail = [..._this.articleListDetail, ...res];
                    }
                    if (res.length < 10) {
                        _this.noMore = true;
                        _this.finishLoding = true;
                        window.removeEventListener("scroll", _this.throttle(), false);
                        console.log(_this.finishLoding)
                    }
                    _this.latestMsgId = _this.articleListDetail[
                    _this.articleListDetail.length - 1].msgId;
                }).finally(() => {
                    _this.loading = false;
                    _this.isLoding = false;
                });
            },

            // 滚动触底加载
            scrollFn() {
                // window.alert("触发scrollFn")
                let clientHeight = document.documentElement.clientHeight - 18; //可视区域
                let scrollHeight = document.body.scrollHeight; // 滚动文档高度
                let scrollTop = parseInt(document.documentElement.scrollTop); // 已滚动的高度
                let height = 300;
                if (scrollTop + clientHeight >= scrollHeight - height && scrollHeight !== 0) {
                    this.req.msgId = this.latestMsgId;
                    this.getList();
                }
            },
            handleTouchMove() {

                let clientHeight = document.documentElement.clientHeight - 18; // 可视区域
                let scrollHeight = document.body.scrollHeight; // 滚动文档高度
                let scrollTop = parseInt(document.documentElement.scrollTop || document.body.scrollTop || window.scrollY); // 已滚动的高度
                let height = 300;

                if (scrollTop + clientHeight >= scrollHeight - height && scrollHeight !== 0) {
                    this.req.msgId = this.latestMsgId;
                    this.getList();
                }
            },

            handleScroll() {
                let currentScrollTop = window.scrollY || window.pageYOffset;

                if (currentScrollTop > this.lastScrollTop) {
                    // 向下滚动
                    this.scrollDown();
                } else if (currentScrollTop < this.lastScrollTop) {
                    // 向上滚动
                    this.scrollUp();
                }

                this.lastScrollTop = currentScrollTop;
            },
            scrollUp() {
                // 处理向上滚动的逻辑
            },
            scrollDown() {
                // 处理向下滚动的逻辑
            },
            throttle(fn, wait) {
                // 实现节流函数
                let timer = null;
                return function () {
                    const context = this;
                    const args = arguments;
                    if (!timer) {
                        timer = setTimeout(function () {
                            fn.apply(context, args);
                            timer = null;
                        }, wait);
                    }
                };
            },
            search() {
                this.loading = true;
                this.isLoding = true;
                this.noMore = false;
                this.req.msgId = null;
                this.getList()
            },
        },
        created() {
            this.getList();
            window.addEventListener("scroll", this.throttle(this.scrollFn, 1000));
            window.addEventListener("touchmove", this.throttle(this.handleTouchMove, 10));


        },
    };
</script>

<style scoped>
    /deep/ .el-input.is-active .el-input__inner, .el-input__inner:focus {
        border-color: #a0c15f !important;
    }

    @media only screen and (max-width: 768px) {
        .detail-content {
            width: 78vw !important;
        }

        .news-date {
            width: 30% !important;
        }

        .news-title {
            width: 70% !important;
        }
    }


    @media only screen and (min-width: 768px) {
        .detail-content {
            width: 50vw !important;
        }

        .news-date {
            width: 20% !important;
        }

        .news-title {
            width: 80% !important;
        }
    }


</style>
