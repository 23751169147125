<template>
  <div class="aboutUs">
    <div class="title-pic">
      <img style="width: 100vw;" src="@/../static/iamges/about/gywm_pic.jpg">
    </div>

    <div class="aboutUs-title">
      <el-menu :default-active="activeIndex2" class="el-menu-demo" mode="horizontal" @select="handleSelect2"
               background-color="#f6f6f6" text-color="#888888" active-text-color="#7b9d38">
        <el-menu-item class="menu-item1" index="1">公司简介</el-menu-item>
        <el-menu-item index="2">创始人简介</el-menu-item>
        <el-menu-item index="3">企业资质</el-menu-item>
        <el-menu-item index="4">合作伙伴</el-menu-item>
      </el-menu>
    </div>
    <div v-if="selectedKeyAbout==1" class="hidden-md-and-down">

      <el-row>
        <el-col :span="20" :offset="2">
          <div style="height: 432px;padding-right: 30px;background-size: 100% 100%"
               :style="{backgroundImage:'url('+require('@/../static/iamges/about/gywm_bg1.png')+')'}">
            <el-row style="padding-top: 20px">
              <el-col :lg="{span:7,offset:3}">
                <img style="width: 100%;margin-top: 20px" src="@/../static/iamges/about/gywm_tit1.png">
              </el-col>

            </el-row>
            <el-col style="margin-top: 30px" :lg="{span:4,offset:2}">
              <img src="@/../static/iamges/about/gywm_pic2.png">
            </el-col>
            <el-col :lg="{span:15,offset:1}" :md="{span:15,offset:3}" :sm="{span:15,offset:3}" :xs="{span:18,offset:4}">
              <p style="color: #888888;font-size: 18px ;margin-top: 40px;padding-right: 10px">
                <span style="color: black;font-size: 22px">苏州迪美格智能科技有限公司</span>是一家国家级高新技术企业，苏州工业园区科技领军单位，2021年入选首批工信部与国家卫健委联合发布的“
                <span style="color: black;">5G+医疗健康应用试点项目</span>”。
              </p>
              <p style="color: #888888;font-size: 18px;margin-top: 30px ;padding-right: 10px">
                公司拥有自主研发的病理切片自动扫描设备、高性能计算服务器集群、生物信息分析中心和光学病理实验室等设施，专注癌症病理大数据分析，通过AI病理检测定位、肿瘤病理亚型分类以及肿瘤病理预后预测等关键技术的不断突破，
                <span style="color: black;font-size: 22px">致力于成为病理AI前沿解决方案的提供商</span>。</p>
            </el-col>
          </div>
        </el-col>
      </el-row>


    </div>

    <div v-if="selectedKeyAbout==1" class="hidden-lg-and-up">

      <el-row>
        <el-col :span="20" :offset="2">
          <div style="height: 432px;padding-right: 30px;"
          >
            <el-row style="padding-top: 20px">
              <el-col :lg="{span:7,offset:3}">
                <img style="width: 100%;margin-top: 20px" src="@/../static/iamges/about/gywm_tit1.png">
              </el-col>

            </el-row>
            <el-col style="margin-top: 30px" :lg="{span:4,offset:2}">
              <img src="@/../static/iamges/about/gywm_pic2.png">
            </el-col>
            <el-col :lg="{span:15,offset:1}">
              <p style="color: #888888;font-size: 18px ;margin-top: 40px;padding-right: 10px">
                <span style="color: black;font-size: 22px">苏州迪美格智能科技有限公司</span>是一家国家级高新技术企业，苏州工业园区科技领军单位，2021年入选首批工信部与国家卫健委联合发布的“
                <span style="color: black;">5G+医疗健康应用试点项目</span>”。
              </p>
              <p style="color: #888888;font-size: 18px;margin-top: 30px ;padding-right: 10px">
                公司拥有自主研发的病理切片自动扫描设备、高性能计算服务器集群、生物信息分析中心和光学病理实验室等设施，专注癌症病理大数据分析，通过AI病理检测定位、肿瘤病理亚型分类以及肿瘤病理预后预测等关键技术的不断突破，
                <span style="color: black;font-size: 22px">致力于成为病理AI前沿解决方案的提供商</span>。</p>
            </el-col>
          </div>
        </el-col>
      </el-row>


    </div>

    <div v-if="selectedKeyAbout==1" class="hidden-md-and-down" style="margin-top: 30px;padding-bottom: 40px">
      <el-row>
        <el-col :span="20" :offset="2">
          <div>
            <el-row style="padding-top: 20px">
              <el-col :lg="{span:7,offset:3}">
                <img style="width: 100%;margin-top: 20px" src="@/../static/iamges/about/gywm_tit2.png">
              </el-col>

            </el-row>
            <div>
              <el-row style="margin-top: 30px">
                <el-col style="display: flex;margin-top: 30px;">
                  <img src="@/../static/iamges/about/licheng.png">

                  <!--                                    <div >-->
                  <!--                                        <img  style="width: 126px;height: 75px;" src="@/../static/iamges/about/gywm_pic3_2.png">-->

                  <!--                                    </div>-->
                  <!--&lt;!&ndash;                                    <div style="display: flex;flex-direction: column;align-items: center;margin-left: 10px" >&ndash;&gt;-->
                  <!--&lt;!&ndash;                                        <div style="background-color: #d0d0d0;width: 3px;height: 30px;"></div>&ndash;&gt;-->
                  <!--&lt;!&ndash;                                        <div style="width: 12px;height: 12px;background-color: #ffffff;border-radius: 50%;&ndash;&gt;-->
                  <!--&lt;!&ndash;                                            border:4px solid #ef9e1e"&ndash;&gt;-->
                  <!--&lt;!&ndash;                                        ></div>&ndash;&gt;-->
                  <!--&lt;!&ndash;                                    </div>&ndash;&gt;-->
                  <!--                                    <div>-->
                  <!--                                        <img style="margin-left: 10px" src="@/../static/iamges/about/gywm_pic3_m.png">-->
                  <!--                                    </div>-->
                  <!--                                    <div>-->
                  <!--                                        <img style="width: 126px;height: 75px;margin-left: 10px" src="@/../static/iamges/about/gywm_pic3_1.png">-->
                  <!--                                        <div >-->
                  <!--                                            <div>2016年</div>-->
                  <!--                                            <div>5月,公司正式成立</div>-->
                  <!--                                        </div>-->
                  <!--                                    </div>-->
                </el-col>
              </el-row>

            </div>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <!--                <el-col :span="20" :offset="2">-->
        <!--                    <img style="margin-top: -250px;padding-bottom: 35px " src="@/../static/iamges/about/gywm_bg2.png">-->
        <!--                </el-col>-->
      </el-row>
    </div>

    <div v-if="selectedKeyAbout==1" class="hidden-lg-and-up" style="margin-top: 30px;padding-bottom: 45px;">
      <el-row>
        <el-col :span="20" :offset="2">
          <div>
            <el-row style="padding-top: 20px">
              <el-col :lg="{span:7,offset:3}">
                <img style="width: 100%;margin-top: 20px" src="@/../static/iamges/about/gywm_tit2.png">
              </el-col>

            </el-row>
            <div>
              <el-row style="margin-top: 30px;">
                <el-col style="display: flex;margin-top: 30px;" :span="24" :offset="0">
                  <img style="width: 98vw ;margin-left: -30px" src="@/../static/iamges/about/licheng.png">


                </el-col>
              </el-row>

            </div>
          </div>
        </el-col>
      </el-row>

    </div>


    <div v-if="selectedKeyAbout==2" style="padding: 40px;margin-bottom: 40px">
      <el-row>
        <el-col :lg="{span:14, offset:4}" :xs="{span:22,offset:1}">
          <p style="font-size: 18px;color: #666666;line-height: 40px">
            创始人<span style="font-size: 20px;color: #505050;font-weight: 600">任思远</span>本科毕业于上海交通大学生物技术专业，研究生先后师从中科院癌症机理专家陈正军教授，
            和欧洲科学院院士著名生物医学信息专家Zoran Obradovic;在生物医学信息领域多项一作论文
            得到包括TonyHunter在内9位欧美科学院院士的引述，并受邀成为多家国际期刊评审，2015年受邀撰写斯普林格出版社出版生物医学信息专著。
          </p>
        </el-col>
      </el-row>

    </div>


    <div v-if="selectedKeyAbout==3" class="hidden-md-and-up" style="height: 650px;margin-bottom: 40px">
      <el-tabs v-model="activeName">
        <el-tab-pane label="资质荣誉" name="资质荣誉">
          <el-row>
            <el-col class="About-rongyu-left-md" :span="22" :offset="1">
              &nbsp;首批工信部与国家卫健委联合发布的“5G+医疗健康应用试点”
              <br/>
              &nbsp;国家级高新技术企业
              <br/>
              &nbsp;江苏省民营科技企业
              <br/>
              &nbsp;苏州工业园区领军人才单位、亚杰摇篮计划学员单位
              <br/>
              &nbsp;...
              <br/>

            </el-col>
            <el-col class="About-rongyu-right-md" :span="10" style="margin-top: 20px">
              <img style="width: 250px;" src="@/../static/iamges/5G.png">
              <img style="width: 250px;" src="@/../static/iamges/gaoqi.jpg">
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="发明专利" name="发明专利">
          <el-row>
            <el-col class="About-rongyu-left-md" :span="22" :offset="1">
              &nbsp;基于强化学习的医学自然语言语义网络反馈式提取系统与方法
              <br/>
              &nbsp;基于模板编辑的分布式医学自然语言文本云处理系统
              <br/>
              &nbsp;数字切片扫描仪的移动对焦方法、扫描方法及装置
              <br/>
              &nbsp;...
              <br/>

            </el-col>
            <!--                <el-col class="About-rongyu-right-md" :span="10">-->
            <!--                    <img style="width: 300px;margin-left: -10px" src="@/../static/iamges/ruanzhu1.jpg">-->
            <!--                    <img style="width: 300px; margin-left: 10px"  src="@/../static/iamges/ruanzhu2.jpg">-->
            <!--                </el-col>-->
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="软件著作权" name="软件著作权">
          <el-row>
            <el-col class="About-rongyu-left-md" :span="22" :offset="1">
              &nbsp;数字切片AI模型管理软件V1.0
              <br/>
              &nbsp;数字切片机器学习训练软件V1.0
              <br/>
              &nbsp;数字切片扫描AI控制系统（简称：扫描控制系统）V1.0
              <br/>
              &nbsp;...
              <br/>

            </el-col>
            <el-col class="About-rongyu-right-md" :span="10">
              <img style="width: 210px;" src="@/../static/iamges/ruanzhu1.jpg">
              <img style="width: 205px;" src="@/../static/iamges/ruanzhu2.jpg">
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="实用新型专利" name="实用新型专利">
          <el-row>
            <el-col class="About-rongyu-left-md" :span="22" :offset="1">
              &nbsp;一种结构紧凑的病理切片扫描二维电动位移平台
              <br/>
              &nbsp;一种快速精确的显微镜对焦装置
              <br/>
              &nbsp;一种用于数字切片扫描的切片位移机构
              <br/>
              &nbsp;...
              <br/>

            </el-col>
            <el-col class="About-rongyu-right-md" :span="10">
              <img style="width: 210px;" src="@/../static/iamges/shiyong1.png">
              <img style="width: 210px;" src="@/../static/iamges/shiyong2.png">
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="外观设计专利" name="外观设计专利">
          <el-row>
            <el-col class="About-rongyu-left-md" :span="22" :offset="1">
              &nbsp;数字切片扫描仪（DM03）
              <br/>
              &nbsp;显微镜坐标识别板
              <br/>

            </el-col>
            <el-col class="About-rongyu-right-md" :span="10">
              <img style="width: 200px;" src="@/../static/iamges/waiguan1.png">
              <img style="width: 200px;" src="@/../static/iamges/waiguan2.png">
            </el-col>
          </el-row>
        </el-tab-pane>
      </el-tabs>
    </div>

    <div v-if="selectedKeyAbout==3" class="About-rongyu hidden-sm-and-down"
         style="height: 550px;width:100vw;margin-bottom: 40px; "
    >


      <el-row>
        <el-col :span="16" :offset="4">
          <div class="btn-group">
            <div class="btns" @mouseover="handleSelect(0)" :style="menuItemStyle[0]">资质荣誉</div>
            <div class="btns" @mouseover="handleSelect(1)" :style="menuItemStyle[1]">发明专利</div>
            <div class="btns" @mouseover="handleSelect(2)" :style="menuItemStyle[2]">软件著作权</div>
            <div class="btns" @mouseover="handleSelect(3)" :style="menuItemStyle[3]">实用新型专利</div>
            <div class="btns" @mouseover="handleSelect(4)" :style="menuItemStyle[4]">外观设计专利</div>
          </div>


        </el-col>
      </el-row>
      <div :style="{backgroundImage:'url('+require('@/../static/iamges/about/gywm_bg1.png')+')'}"
           style="height: 450px;background-size: 93% 100%;margin-left: 30px">
        <el-row v-if="selectedKey===0">
          <el-col class="About-rongyu-left" :span="8" :offset="4" style="margin-top: 80px">
            <!--                    <el-row class="left-title">资质荣誉4项</el-row>-->
            <el-row class="left-text">
              <el-col>
                &nbsp;首批工信部与国家卫健委联合发布的“5G+医疗健康”应用试点
              </el-col>
            </el-row>
            <el-row>
              <el-col style="margin: 20px 0px">
                &nbsp;国家级高新技术企业
              </el-col>
            </el-row>
            <el-row>
              <el-col>
                &nbsp;江苏省民营科技企业
              </el-col>
              <el-col style="margin: 20px 0px">
                &nbsp;苏州工业园区领军人才单位、亚杰摇篮计划学员单位
              </el-col>
              <el-col>
                &nbsp;...
              </el-col>

            </el-row>
          </el-col>
          <el-col class="About-rongyu-right-md" :span="10" style="margin-top: 80px">
            <img style="width: 250px;margin-left: -36px" src="@/../static/iamges/5G.png">
            <img style="width: 250px; " src="@/../static/iamges/gaoqi.jpg">
          </el-col>
          <el-row>
            <el-col>
              <img>
            </el-col>
          </el-row>
        </el-row>

        <el-row v-if="selectedKey===1">
          <el-col class="About-rongyu-left" style="margin-top: 80px" :span="8" :offset="4">
            <!--                    <el-row class="left-title">发明专利7项</el-row>-->
            <el-row class="left-text">
              <el-col>
                &nbsp;基于强化学习的医学自然语言语义网络反馈式提取系统与方法
              </el-col>
            </el-row>
            <el-row>
              <el-col style="margin: 20px 0px">
                &nbsp;基于模板编辑的分布式医学自然语言文本云处理系统
              </el-col>
            </el-row>
            <el-row>
              <el-col>
                &nbsp;数字切片扫描仪的移动对焦方法、扫描方法及装置
              </el-col>
              <el-col style="margin: 20px 0px">
                &nbsp;...
              </el-col>
            </el-row>
          </el-col>
          <!--                <el-col class="About-rongyu-right-md" :span="10">-->
          <!--                    <img style="width: 300px;margin-left: -10px" src="@/../static/iamges/ruanzhu1.jpg">-->
          <!--                    <img style="width: 300px; margin-left: 10px"  src="@/../static/iamges/ruanzhu2.jpg">-->
          <!--                </el-col>-->
        </el-row>

        <el-row v-if="selectedKey===2">
          <el-col class="About-rongyu-left" style="margin-top: 80px" :span="8" :offset="4">
            <!--                    <el-row class="left-title">软件著作16项</el-row>-->
            <el-row class="left-text">
              <el-col>
                &nbsp;数字切片AI模型管理软件V1.0
              </el-col>
            </el-row>
            <el-row>
              <el-col style="margin: 20px 0px">
                &nbsp;数字切片机器学习训练软件V1.0
              </el-col>
            </el-row>
            <el-row>
              <el-col>
                &nbsp;数字切片扫描AI控制系统（简称：扫描控制系统）V1.0
              </el-col>
              <el-col style="margin: 20px 0px">
                ...
              </el-col>
            </el-row>
          </el-col>
          <el-col class="About-rongyu-right-md" style="margin-top: 80px" :span="10">
            <img style="width: 250px;height:320px;margin-left: -10px" src="@/../static/iamges/ruanzhu1.jpg">
            <img style="width: 250px;height:320px; margin-left: 10px" src="@/../static/iamges/ruanzhu2.jpg">
          </el-col>
        </el-row>

        <el-row v-if="selectedKey===3">
          <el-col class="About-rongyu-left" :span="8" :offset="4" style="margin-top: 80px">
            <!--                    <el-row class="left-title">实用新型专利11项</el-row>-->
            <el-row class="left-text">
              <el-col>
                &nbsp;一种结构紧凑的病理切片扫描二维电动位移平台
              </el-col>
            </el-row>
            <el-row>
              <el-col style="margin: 20px 0px">
                &nbsp;一种快速精确的显微镜对焦装置
              </el-col>
            </el-row>
            <el-row>
              <el-col>
                &nbsp;一种用于数字切片扫描的切片位移机构
              </el-col>
              <el-col style="margin: 20px 0px">
                &nbsp;...
              </el-col>
            </el-row>
          </el-col>
          <el-col class="About-rongyu-right-md" :span="10" style="margin-top: 80px">
            <img style="width: 250px;height:320px;" src="@/../static/iamges/shiyong1.png">
            <img style="width: 250px;height:320px;margin-left: 10px " src="@/../static/iamges/shiyong2.png">
          </el-col>
        </el-row>

        <el-row v-if="selectedKey===4">
          <el-col class="About-rongyu-left" :span="8" :offset="4" style="margin-top: 80px">
            <!--                    <el-row class="left-title">外观设计专利2项</el-row>-->
            <el-row class="left-text">
              <el-col>
                &nbsp;数字切片扫描仪（DM03）
              </el-col>
            </el-row>
            <el-row>
              <el-col style="margin: 20px 0px">
                &nbsp;显微镜坐标识别板
              </el-col>
            </el-row>

          </el-col>
          <el-col class="About-rongyu-right" :span="10" style="margin-top: 80px">
            <img style="width: 250px;height:320px;margin-left: 40px" src="@/../static/iamges/waiguan1.png">
            <img style="width: 250px;height:320px; margin-left: 10px" src="@/../static/iamges/waiguan2.png">
          </el-col>
        </el-row>
      </div>

    </div>

    <div v-if="selectedKeyAbout==4" style="padding: 40px">
      <el-row>
        <el-col :lg="{span:14,offset:4}">
          <img style="width: 100%" src="@/../static/iamges/about/gywm_pichzhb.jpg">
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
export default {
  name: "aboutUs",
  data() {
    return {
      activeIndex: '1',
      menuItemStyle: [
        {backgroundColor: " rgba(160,193,95,1)", color: "#fff"},
        {backgroundColor: " rgba(160,193,95,0.2)", color: "#000"},
        {backgroundColor: " rgba(160,193,95,0.2)", color: "#000"},
        {backgroundColor: " rgba(160,193,95,0.2)", color: "#000"},
        {backgroundColor: " rgba(160,193,95,0.2)", color: "#000"}
      ],
      selectedKey: 0,
      activeName: '资质荣誉',
      activeIndex2: '1',
      selectedKeyAbout: "1",

    }
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
      this.selectedKey = key;
      console.log(this.selectedKey);
      this.menuItemStyle = [
        {backgroundColor: " rgba(160,193,95,0.2)", color: "#000"},
        {backgroundColor: " rgba(160,193,95,0.2)", color: "#000"},
        {backgroundColor: " rgba(160,193,95,0.2)", color: "#000"},
        {backgroundColor: " rgba(160,193,95,0.2)", color: "#000"},
        {backgroundColor: " rgba(160,193,95,0.2)", color: "#000"}
      ]
      this.menuItemStyle[key] = {backgroundColor: " rgba(160,193,95,1)", color: "#fff"}
    },
    handleSelect2(key2, keyPath2) {
      console.log(key2, keyPath2);
      this.selectedKeyAbout = key2
    }
  },
  mounted() {
    this.$emit("handleSelect", "3", ["3"])

  }
}
</script>

<style scoped>

/*小于992*/
@media only screen and (max-width: 992px) {


  .aboutUs-detail {
    padding: 0px 50px;
  }

  .About-rongyu-right-md {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

  }

  .About-rongyu-right-md img {
    box-shadow: 5px 5px 10px -5px black;
  }

  .el-menu--horizontal > .menu-item1 {
    margin-left: 0px !important;
  }

  .el-menu--horizontal > .el-menu-item {
    font-size: 20px;
    width: 45% !important;

  }

}

/*大于992*/
@media only screen and (min-width: 992px) {

  .About-rongyu-right-md {
    display: flex;
    justify-content: flex-start;
    margin-left: -25px;
  }

  .About-rongyu-right-md img {
    box-shadow: 5px 5px 10px -5px black;
  }
}


.aboutUs {
  height: 100%;
  width: 100%;
  overflow: hidden;
}


.About-rongyu-right {
  display: flex;
  justify-content: flex-start;
  margin-left: -80px;
}

.About-rongyu-right img {
  box-shadow: 5px 5px 10px -5px black;
}


.About-rongyu el-menu-item {
  font-size: 30px;
}

.rongyu-detail {
  font-size: 23px;
  font-weight: 500;
  width: 230px;
  border: 1px solid rgba(244, 244, 244, 0.5)
}

.About-rongyu-left, .About-rongyu-right {
  text-align: left;
  margin-top: 30px;
  color: #888888;
  font-size: 14px;
}

.left-text {
  margin-top: 30px;
}


.btn-group {
  display: flex;
  justify-content: space-around;
}

.btns {
  width: 100%;
  height: 60px;
  line-height: 60px;
  font-size: 20px;
  /*border: 1px solid rgba(247, 247, 247, 1);*/
}

p {
  white-space: normal;
  text-align: left;
  text-indent: 2em;
  margin: 10px auto;
  line-height: 35px;

}

.el-menu--horizontal > .menu-item1 {
  margin-left: 280px;
}

.el-menu--horizontal > .el-menu-item {
  font-size: 20px;
  width: 11%;
}


</style>
