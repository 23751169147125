<template>
    <div class="product-details">
        <div class="title">
            <div class="title-text">产品介绍</div>
        </div>
        <div class="details">
            <div>
                <el-row class="carousel-box">
                    <el-col>
                        <el-carousel :height="bannerHeight+'px'" :change="imgLoad">
                            <el-carousel-item v-for="(url,index) in pictureList" :key="index" >
                                <img
                                        class="carousel-img"
                                        :src="url"
                                        ref="bannerHeight"
                                        @load="imgLoad"
                                />
                            </el-carousel-item>
                        </el-carousel>
                    </el-col>
                    <el-col>
                      <p class="text">
                          <span style="font-size: 18px;color: gray;font-weight: 600">Turingene是一款迪美格智能科技有限公司自主研发的app，其功能是病理切片扫描<br/></span>
<!--                         <span style="color: #5D9AF4;font-weight: 600">1、切片扫描功能：<br/></span>-->
                          通过此app，用户可以自主购买相应的切片套餐，从而进行病理切片的扫描<br/>同时扫描完成的切片可以进行内外部分享<br/>
<!--                         <span style="color: #5D9AF4;font-weight: 600">2、报告分享功能：<br/></span>-->
<!--                          医生之间可以进行无障碍的报告分享，方便医生之间对病人病情的沟通与交流<br/>-->
<!--                          <span style="color: #5D9AF4;font-weight: 600">3、聊天功能<br/></span>-->
<!--                          医生和病人之间、医生和医生之间可以建群沟通，方便医生对每个病人病情的精准分析-->
                      </p>
                    </el-col>
                </el-row>
            </div>


        </div>
    </div>
</template>

<script>
    export default {
        name: "productDetails",
        mounted() {
            this.$emit("handleSelect","1",["1"])
            this.imgLoad()
            window.addEventListener('resize', () => {
                if (this.$refs.bannerHeight[0].height) {
                    this.bannerHeight = this.$refs.bannerHeight[0].height
                    this.imgLoad()
                }
            }, false)
        },
        data(){
            return{
                pictureList: [
                    // require("@/../static/pinjie.png"),
                    require("@/../static/iamges/slide.jpg"),
                    require("@/../static/iamges/scan.jpg"),
                    require("@/../static/iamges/pinjie.jpg"),
                    // require("@/../static/iamges/contact.jpg"),

                ],
                bannerHeight:700
            }
        },
        methods:{
            imgLoad () {
                this.$nextTick(() => {
                    try {
                        // this.$refs.bannerHeight[0].width = window.innerWidth/2
                        if (this.$refs.bannerHeight[0].height){
                            this.bannerHeight = this.$refs.bannerHeight[0].height
                        }
                    }catch (e) {
                        console.log()
                    }
                })
            }
        },

    }
</script>

<style scoped lang="css">
    .product-details {

    }
    .title{
        width: 100%;
        height: 90px;
        background-color: rgba(217, 217, 217, 0.37);

    }
    .title-text{
        font-size: 32px;
        font-weight: 600;
        color: #074A81;
        line-height: 90px;
        text-align: center;
    }

    .details{
        margin-top: 50px;
        margin-bottom: 30px;
    }
    .carousel-img {
        border: 1px solid #ffffff;
        border-radius: 10px;
        box-shadow: 1px 2px 12px 6px #e5e8eddb;

    }
    .text{
        text-align: left;
        margin-left: 40px;
        line-height: 30px;
    }


     /*大于992*/
    @media only screen and (min-width: 992px) {
        .carousel-box {
            display: flex;
            align-items: center;
        }
        .text{
           text-align: left;
            white-space: normal;
            line-height: 45px;
        }

    }


    /*大于1200*/
    @media only screen and (min-width: 1200px) {
        .carousel-img {
            width: 45%;
        }
    }


    /*    992-1200之间*/
    @media screen and (min-width: 992px)and(max-width: 1200px) {
        .carousel-img {
            width: 100%;
        }
    }


    /*    768-992之间*/
    @media screen and (min-width: 768px)and(max-width: 992px) {
        .carousel-img {
            width: 80%;
        }
        .text{
            text-align: left;
        }

    }

    /*    小于768*/
    @media only screen and (max-width: 768px) {
        .carousel-img {
            width: 80%;
        }
        .text{
            text-align:left;
            white-space: normal;
            line-height: 30px;
            padding: 20px;
        }
    }



</style>