<template>
    <div>
        <div class="login-container">
            <h2>登录</h2>
            <form @submit.prevent="login">
                <label for="phone">手机号:</label>
                <input type="text" id="phone" v-model="phone" required />

                <label for="password">密码:</label>
                <input type="password" id="password" v-model="password" required />

                <button type="submit">登录</button>
            </form>
        </div>
    </div>

</template>

<script>
    import {request} from "@/../src/utils/utils";

    export default {
        data() {
            return {
                phone: '12345678901',
                password: 'qwer123456'
            };
        },
        methods: {
            login() {
                let _this = this
                let data = {
                    phone:_this.phone,
                    password:_this.password
                }

                request("POST", "/user/login", data).then((res) => {
                    console.log(res)
                    _this.$router.push("editNews")
                })



            },
        }
    };
</script>

<style scoped>
    .login-container {
        max-width: 400px;
        margin: auto;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 5px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        margin-top: 100px;
    }

    form {
        display: flex;
        flex-direction: column;
    }

    label {
        margin-bottom: 5px;
    }

    input {
        margin-bottom: 10px;
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 3px;
    }

    button {
        background-color: #4caf50;
        color: white;
        padding: 10px;
        border: none;
        border-radius: 3px;
        cursor: pointer;
    }

    button:hover {
        background-color: #45a049;
    }
</style>
