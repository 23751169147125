<template>
    <div class="contact">
        <div class="contact-head">
            <img style="width: 100%;" src="@/../static/iamges/hire/zp_pic.jpg">
        </div>
        <div class="zhaopin-detail">
            <el-row class="detail-text"
                    style="border: 2px solid #eeeeee;margin-top: 20px;position: relative ;padding-bottom: 20px ">
                <div>
                    <img style="position: absolute;top:0%;left: 0%" src="@/../static/iamges/hire/zp_conbglu.png">
                </div>

                <el-col class="detail-left" :lg="{span:10}" :md="{span:10}" :sm="{span:10}" :xs="{span:20}"
                        style="margin-top: 30px">
                    <el-row>
                        <el-col :lg="{span:6,offset:1}" :md="{span:7 ,offset:1}" :sm="{span:7 ,offset:1}"
                                :xs="{span:8 ,offset:3}">
                            <img style=" width: 90px; height: 40px;" src="@/../static/iamges/hire/zp_conl.png">
                            <div style="font-size: 22px;color: white ;position: relative;top:-38px;">职位</div>
                        </el-col>
                        <el-col :lg="{span:10 ,offset:0}" :md="{span:10 ,offset:0}" :sm="{span:10 ,offset:0}"
                                :xs="{span:12 ,offset:0}">
                            <div style="width: 190px">
                                <img style="margin-left: 0px; width: 190px; height: 40px;"
                                     src="@/../static/iamges/hire/zp_conr.png">
                                <div style="font-size: 22px;color: #000000c7;position: relative;top:-38px;">生物医学信息</div>
                            </div>

                        </el-col>

                    </el-row>
                    <el-row>
                        <el-col :lg="{span:16 ,offset:1}" :md="{span:16 ,offset:1}" :sm="{span:16 ,offset:1}"
                                :xs="{span:16 ,offset:8}" style="margin-left: 35px">
                            <div style="display: flex;justify-content: flex-start;align-items: center">
                                <div style="color: #7b9d38;margin-top: -20px;width: 85px">
                                    工作地点
                                </div>
                                <div style="margin-top: -20px ;width: 30px;text-align: left;margin-left: 3px">
                                    <img style="width: 9px;" src="@/../static/iamges/hire/zp_condotgrey.png">

                                </div>
                                <div style="color:#000000c7;margin-top: -20px;width: 85px;;text-align: left">苏州</div>
                            </div>
                        </el-col>
                    </el-row>


                    <el-row style="margin-top: 40px">
                        <el-col :lg="{span:16 ,offset:1}" :md="{span:16 ,offset:1}" :sm="{span:16 ,offset:1}"
                                :xs="{span:16 ,offset:1}" style="margin-left: 35px">
                            <div style="display: flex;justify-content: flex-start;align-items: center">
                                <div style="color: #7b9d38;margin-top: -20px;width: 85px">
                                    学历要求
                                </div>
                                <div style="margin-top: -20px ;width: 30px;text-align: left;margin-left: 3px">
                                    <img style="width: 9px;" src="@/../static/iamges/hire/zp_condotgrey.png">

                                </div>
                                <div style="color:#000000c7;margin-top: -20px;width: 85px;text-align: left">硕士以上</div>
                            </div>
                        </el-col>
                    </el-row>


                    <el-row style="color: #818181;text-align: left;margin-top: 30px">
                        <el-col :span="24" :offset="0" style="margin-left: 48px">
                            如有意向<br/>
                            请将您的简历发送至 hr@medppp.com
                        </el-col>
                    </el-row>

                </el-col>
                <el-col style="margin-top: 30px" class="detail-center" :lg="{span:1}" :md="{span:1}" :sm="{span:1}"
                        :xs="{span:0}">
                    <div style="height: 85%;width: 2px;background-color: #eeeeee">
                    </div>


                </el-col>
                <el-col style="margin-top: 40px" class="detail-right" :lg="{span:13}" :md="{span:13}" :sm="{span:13}"
                        :xs="{span:22,offset:2}">
                    <el-row>
                        <el-col :lg="{span:20}" :md="{span:20}" :sm="{span:20}" :xs="{span:20}">
                            <div>
                                <div style="display: flex;justify-content: flex-start;align-items: center">
                                    <img style="width: 9px;height: 9px"
                                         src="@/../static/iamges/hire/zp_condotorange.png">
                                    <div style="font-weight: 600;margin-left: 10px;font-size: 15px ;color: #2e2e2e">
                                        岗位职责
                                    </div>

                                </div>
                                <div style="display: flex;flex-direction: column;align-items: flex-start;justify-content: space-between;color: #818181;
                                margin-top: 10px;text-align: left">
                                    <div>1、从事生物医学大数据分析，从海量信息中发现生物学意义</div>
                                    <div style="margin: 10px 0px">2、特征提取，机器学习，模型搭建</div>
                                    <div>3、实现生物医学规律发现，疾病预测，辅助诊疗等</div>
                                </div>
                            </div>

                            <div style="margin-top: 20px">
                                <div style="display: flex;justify-content: flex-start;align-items: center">
                                    <img style="width: 9px;height: 9px"
                                         src="@/../static/iamges/hire/zp_condotorange.png">
                                    <div style="font-weight: 600;margin-left: 10px;font-size: 15px ;color: #2e2e2e">
                                        应聘要求
                                    </div>

                                </div>
                                <div style="display: flex;flex-direction: column;align-items: flex-start;justify-content: space-between;color: #818181;
                                margin-top: 10px;text-align: left">
                                    <div>1、硕士以上学历,生物信息，计算机，统计相关专业优先</div>
                                    <div style="margin: 10px 0px">2、掌握至少一门编程语言</div>
                                    <div> 3、有团队合作精神,对生物医学信息，系统生物学有浓厚兴趣</div>
                                    <div style="margin: 10px 0px"> 4、有想象力，好奇心，轻松阅读英文文献</div>
                                    <div>5、有生物信息学实践经验和论文发表者优先。</div>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                </el-col>

                <div>
                    <img src="@/../static/iamges/hire/zp_conbgrd.png" style="position: absolute;bottom:0%;right:0%">
                </div>


            </el-row>

            <el-row class="detail-text"
                    style="border: 2px solid #eeeeee;margin-top: 20px;position: relative;padding-bottom: 20px; ">
                <div>
                    <img style="position: absolute;top:0%;left: 0%" src="@/../static/iamges/hire/zp_conbglu.png">
                </div>

                <el-col class="detail-left" :lg="{span:10}" :md="{span:10}" :sm="{span:10}" :xs="{span:20}"
                        style="margin-top: 30px">
                    <el-row>
                        <el-col :lg="{span:6,offset:1}" :md="{span:7 ,offset:1}" :sm="{span:7 ,offset:1}"
                                :xs="{span:8 ,offset:3}">
                            <img style=" width: 90px; height: 40px;" src="@/../static/iamges/hire/zp_conl.png">
                            <div style="font-size: 22px;color: white ;position: relative;top:-38px;">职位</div>
                        </el-col>
                        <el-col :lg="{span:10 ,offset:0}" :md="{span:10 ,offset:0}" :sm="{span:10 ,offset:0}"
                                :xs="{span:12 ,offset:0}">
                            <div style="width: 210px">
                                <img style="margin-left: 0px; width: 210px; height: 40px;"
                                     src="@/../static/iamges/hire/zp_conr.png">
                                <div style="font-size: 22px;color: #000000c7;position: relative;top:-38px;">生物医学编辑(兼职)
                                </div>
                            </div>

                        </el-col>

                    </el-row>
                    <el-row>
                        <el-col :lg="{span:16 ,offset:1}" :md="{span:16 ,offset:1}" :sm="{span:16 ,offset:1}"
                                :xs="{span:16 ,offset:8}" style="margin-left: 35px">
                            <div style="display: flex;justify-content: flex-start;align-items: center">
                                <div style="color: #7b9d38;margin-top: -20px;width: 85px">
                                    工作地点
                                </div>
                                <div style="margin-top: -20px ;width: 30px;text-align: left;margin-left: 3px">
                                    <img style="width: 9px;" src="@/../static/iamges/hire/zp_condotgrey.png">

                                </div>
                                <div style="color:#000000c7;margin-top: -20px;width: 85px;;text-align: left">苏州</div>
                            </div>
                        </el-col>
                    </el-row>


                    <el-row style="margin-top: 40px">
                        <el-col :lg="{span:16 ,offset:1}" :md="{span:16 ,offset:1}" :sm="{span:16 ,offset:1}"
                                :xs="{span:16 ,offset:1}" style="margin-left: 35px">
                            <div style="display: flex;justify-content: flex-start;align-items: center">
                                <div style="color: #7b9d38;margin-top: -20px;width: 85px">
                                    学历要求
                                </div>
                                <div style="margin-top: -20px ;width: 30px;text-align: left;margin-left: 3px">
                                    <img style="width: 9px;" src="@/../static/iamges/hire/zp_condotgrey.png">

                                </div>
                                <div style="color:#000000c7;margin-top: -20px;width: 85px;text-align: left">硕士以上</div>
                            </div>
                        </el-col>
                    </el-row>


                    <el-row style="color: #818181;text-align: left;margin-top: 30px">
                        <el-col :span="24" :offset="0" style="margin-left: 48px">
                            如有意向<br/>
                            请将您的简历发送至 hr@medppp.com
                        </el-col>
                    </el-row>

                </el-col>
                <el-col style="margin-top: 30px" class="detail-center" :lg="{span:1}" :md="{span:1}" :sm="{span:1}"
                        :xs="{span:0}">
                    <div style="height: 85%;width: 2px;background-color: #eeeeee">
                    </div>


                </el-col>
                <el-col style="margin-top: 40px" class="detail-right" :lg="{span:13}" :md="{span:13}" :sm="{span:13}"
                        :xs="{span:22,offset:2}">
                    <el-row>
                        <el-col :lg="{span:20}" :md="{span:20}" :sm="{span:20}" :xs="{span:20}">
                            <div>
                                <div style="display: flex;justify-content: flex-start;align-items: center">
                                    <img style="width: 9px;height: 9px"
                                         src="@/../static/iamges/hire/zp_condotorange.png">
                                    <div style="font-weight: 600;margin-left: 10px;font-size: 15px ;color: #2e2e2e">
                                        岗位职责
                                    </div>

                                </div>
                                <div style="display: flex;flex-direction: column;align-items: flex-start;justify-content: space-between;color: #818181;
                                margin-top: 10px;text-align: left">
                                    <div>1、阅读最新生物医学英文文献
                                    </div>
                                    <div style="margin: 10px 0px"> 2、撰写生物医学前沿文档</div>

                                </div>
                            </div>

                            <div style="margin-top: 20px">
                                <div style="display: flex;justify-content: flex-start;align-items: center">
                                    <img style="width: 9px;height: 9px"
                                         src="@/../static/iamges/hire/zp_condotorange.png">
                                    <div style="font-weight: 600;margin-left: 10px;font-size: 15px ;color: #2e2e2e">
                                        应聘要求
                                    </div>

                                </div>
                                <div style="display: flex;flex-direction: column;align-items: flex-start;justify-content: space-between;color: #818181;
                                margin-top: 10px;text-align: left">
                                    <div>1、生物医学相关专业,重点院校硕士及以上学历
                                    </div>
                                    <div style="margin: 10px 0px">2、有团队合作精神,对生物医学信息和人工智能有浓厚兴趣</div>
                                    <div>3、有想象力，好奇心，轻松阅读英文文献</div>
                                    <div>4、有临床背景者优先，有实验室工作经历者优先</div>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                </el-col>

                <div>
                    <img src="@/../static/iamges/hire/zp_conbgrd.png" style="position: absolute;bottom:0%;right:0%">
                </div>


            </el-row>

            <el-row class="detail-text"
                    style="border: 2px solid #eeeeee;margin-top: 20px;position: relative  ;padding-bottom: 20px;">
                <div>
                    <img style="position: absolute;top:0%;left: 0%" src="@/../static/iamges/hire/zp_conbglu.png">
                </div>

                <el-col class="detail-left" :lg="{span:10}" :md="{span:10}" :sm="{span:10}" :xs="{span:20}"
                        style="margin-top: 30px">
                    <el-row>
                        <el-col :lg="{span:6,offset:1}" :md="{span:7 ,offset:1}" :sm="{span:7 ,offset:1}"
                                :xs="{span:8 ,offset:3}">
                            <img style=" width: 90px; height: 40px;" src="@/../static/iamges/hire/zp_conl.png">
                            <div style="font-size: 22px;color: white ;position: relative;top:-38px;">职位</div>
                        </el-col>
                        <el-col :lg="{span:10 ,offset:0}" :md="{span:10 ,offset:0}" :sm="{span:10 ,offset:0}"
                                :xs="{span:12 ,offset:0}">
                            <div style="width: 190px">
                                <img style="margin-left: 0px; width: 190px; height: 40px;"
                                     src="@/../static/iamges/hire/zp_conr.png">
                                <div style="font-size: 22px;color: #000000c7;position: relative;top:-38px;">Python工程师
                                </div>
                            </div>

                        </el-col>

                    </el-row>
                    <el-row>
                        <el-col :lg="{span:16 ,offset:1}" :md="{span:16 ,offset:1}" :sm="{span:16 ,offset:1}"
                                :xs="{span:16 ,offset:8}" style="margin-left: 35px">
                            <div style="display: flex;justify-content: flex-start;align-items: center">
                                <div style="color: #7b9d38;margin-top: -20px;width: 85px">
                                    工作地点
                                </div>
                                <div style="margin-top: -20px ;width: 30px;text-align: left;margin-left: 3px">
                                    <img style="width: 9px;" src="@/../static/iamges/hire/zp_condotgrey.png">

                                </div>
                                <div style="color:#000000c7;margin-top: -20px;width: 85px;;text-align: left">苏州</div>
                            </div>
                        </el-col>
                    </el-row>


                    <el-row style="margin-top: 40px">
                        <el-col :lg="{span:16 ,offset:1}" :md="{span:16 ,offset:1}" :sm="{span:16 ,offset:1}"
                                :xs="{span:16 ,offset:1}" style="margin-left: 35px">
                            <div style="display: flex;justify-content: flex-start;align-items: center">
                                <div style="color: #7b9d38;margin-top: -20px;width: 85px">
                                    学历要求
                                </div>
                                <div style="margin-top: -20px ;width: 30px;text-align: left;margin-left: 3px">
                                    <img style="width: 9px;" src="@/../static/iamges/hire/zp_condotgrey.png">

                                </div>
                                <div style="color:#000000c7;margin-top: -20px;width: 85px;text-align: left">硕士以上</div>
                            </div>
                        </el-col>
                    </el-row>


                    <el-row style="color: #818181;text-align: left;margin-top: 30px">
                        <el-col :span="24" :offset="0" style="margin-left: 48px">
                            如有意向<br/>
                            请将您的简历发送至 hr@medppp.com
                        </el-col>
                    </el-row>

                </el-col>
                <el-col style="margin-top: 30px" class="detail-center" :lg="{span:1}" :md="{span:1}" :sm="{span:1}"
                        :xs="{span:0}">
                    <div style="height: 85%;width: 2px;background-color: #eeeeee">
                    </div>


                </el-col>
                <el-col style="margin-top: 40px" class="detail-right" :lg="{span:13}" :md="{span:13}" :sm="{span:13}"
                        :xs="{span:22,offset:2}">
                    <el-row>
                        <el-col :lg="{span:20}" :md="{span:20}" :sm="{span:20}" :xs="{span:20}">
                            <div>
                                <div style="display: flex;justify-content: flex-start;align-items: center">
                                    <img style="width: 9px;height: 9px"
                                         src="@/../static/iamges/hire/zp_condotorange.png">
                                    <div style="font-weight: 600;margin-left: 10px;font-size: 15px ;color: #2e2e2e">岗位职责
                                    </div>

                                </div>
                                <div style="display: flex;flex-direction: column;align-items: flex-start;justify-content: space-between;color: #818181;
                                margin-top: 10px;text-align: left">
                                    <div>1、对数据敏感，挖掘有效生物医疗相关的数据</div>
                                    <div style="margin: 10px 0px">2、协助人工智能工程师，提供可靠健壮的数据</div>
                                    <div>3、对爬虫系统做系统性的功能升级和补充</div>
                                </div>
                            </div>

                            <div style="margin-top: 20px">
                                <div style="display: flex;justify-content: flex-start;align-items: center">
                                    <img style="width: 9px;height: 9px"
                                         src="@/../static/iamges/hire/zp_condotorange.png">
                                    <div style="font-weight: 600;margin-left: 10px;font-size: 15px ;color: #2e2e2e">应聘要求
                                    </div>

                                </div>
                                <div style="display: flex;flex-direction: column;align-items: flex-start;justify-content: space-between;color: #818181;
                                margin-top: 10px;text-align: left">
                                    <div>1、有扎实的编程能力和抽象能力，有优秀的设计和代码品位，热爱编程


                                    </div>
                                    <div style="margin: 10px 0px">2、有良好的数据结构和算法基础</div>
                                    <div>3、熟悉常用的数据库如: oracle、mysql、redis</div>
                                    <div style="margin: 10px 0px">4、熟悉Python</div>
                                    <div>5、有爬虫经验优先，使用过scrapy高并发爬虫优先</div>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                </el-col>

                <div>
                    <img src="@/../static/iamges/hire/zp_conbgrd.png" style="position: absolute;bottom:0%;right:0%">
                </div>


            </el-row>

            <el-row class="detail-text"
                    style="border: 2px solid #eeeeee;margin-top: 20px;position: relative ;padding-bottom: 20px; ">
                <div>
                    <img style="position: absolute;top:0%;left: 0%" src="@/../static/iamges/hire/zp_conbglu.png">
                </div>

                <el-col class="detail-left" :lg="{span:10}" :md="{span:10}" :sm="{span:10}" :xs="{span:20}"
                        style="margin-top: 30px">
                    <el-row>
                        <el-col :lg="{span:6,offset:1}" :md="{span:7 ,offset:1}" :sm="{span:7 ,offset:1}"
                                :xs="{span:8 ,offset:3}">
                            <img style=" width: 90px; height: 40px;" src="@/../static/iamges/hire/zp_conl.png">
                            <div style="font-size: 22px;color: white ;position: relative;top:-38px;">职位</div>
                        </el-col>
                        <el-col :lg="{span:10 ,offset:0}" :md="{span:10 ,offset:0}" :sm="{span:10 ,offset:0}"
                                :xs="{span:12 ,offset:0}">
                            <div style="width: 190px">
                                <img style="margin-left: 0px; width: 190px; height: 40px;"
                                     src="@/../static/iamges/hire/zp_conr.png">
                                <div style="font-size: 22px;color: #000000c7;position: relative;top:-38px;">C++开发工程师
                                </div>
                            </div>

                        </el-col>

                    </el-row>
                    <el-row>
                        <el-col :lg="{span:16 ,offset:1}" :md="{span:16 ,offset:1}" :sm="{span:16 ,offset:1}"
                                :xs="{span:16 ,offset:8}" style="margin-left: 35px">
                            <div style="display: flex;justify-content: flex-start;align-items: center">
                                <div style="color: #7b9d38;margin-top: -20px;width: 85px">
                                    工作地点
                                </div>
                                <div style="margin-top: -20px ;width: 30px;text-align: left;margin-left: 3px">
                                    <img style="width: 9px;" src="@/../static/iamges/hire/zp_condotgrey.png">

                                </div>
                                <div style="color:#000000c7;margin-top: -20px;width: 85px;;text-align: left">苏州</div>
                            </div>
                        </el-col>
                    </el-row>


                    <el-row style="margin-top: 40px">
                        <el-col :lg="{span:16 ,offset:1}" :md="{span:16 ,offset:1}" :sm="{span:16 ,offset:1}"
                                :xs="{span:16 ,offset:1}" style="margin-left: 35px">
                            <div style="display: flex;justify-content: flex-start;align-items: center">
                                <div style="color: #7b9d38;margin-top: -20px;width: 85px">
                                    学历要求
                                </div>
                                <div style="margin-top: -20px ;width: 30px;text-align: left;margin-left: 3px">
                                    <img style="width: 9px;" src="@/../static/iamges/hire/zp_condotgrey.png">

                                </div>
                                <div style="color:#000000c7;margin-top: -20px;width: 85px;text-align: left">本科以上</div>
                            </div>
                        </el-col>
                    </el-row>


                    <el-row style="color: #818181;text-align: left;margin-top: 30px">
                        <el-col :span="24" :offset="0" style="margin-left: 48px">
                            如有意向<br/>
                            请将您的简历发送至 hr@medppp.com
                        </el-col>
                    </el-row>

                </el-col>
                <el-col style="margin-top: 30px" class="detail-center" :lg="{span:1}" :md="{span:1}" :sm="{span:1}"
                        :xs="{span:0}">
                    <div style="height: 85%;width: 2px;background-color: #eeeeee">
                    </div>


                </el-col>
                <el-col style="margin-top: 40px" class="detail-right" :lg="{span:13}" :md="{span:13}" :sm="{span:13}"
                        :xs="{span:22,offset:2}">
                    <el-row>
                        <el-col :lg="{span:20}" :md="{span:20}" :sm="{span:20}" :xs="{span:20}">
                            <div>
                                <div style="display: flex;justify-content: flex-start;align-items: center">
                                    <img style="width: 9px;height: 9px"
                                         src="@/../static/iamges/hire/zp_condotorange.png">
                                    <div style="font-weight: 600;margin-left: 10px;font-size: 15px ;color: #2e2e2e">岗位职责
                                    </div>

                                </div>
                                <div style="display: flex;flex-direction: column;align-items: flex-start;justify-content: space-between;color: #818181;
                                margin-top: 10px;text-align: left">
                                    <div>1、生物医学影像数据分析功能模块开发

                                    </div>
                                    <div style="margin: 10px 0px">2、入式硬件控制模块开发</div>
                                    <div> 3、机器学习，迁移学习商业化部署</div>
                                </div>
                            </div>

                            <div style="margin-top: 20px">
                                <div style="display: flex;justify-content: flex-start;align-items: center">
                                    <img style="width: 9px;height: 9px"
                                         src="@/../static/iamges/hire/zp_condotorange.png">
                                    <div style="font-weight: 600;margin-left: 10px;font-size: 15px ;color: #2e2e2e">应聘要求
                                    </div>

                                </div>
                                <div style="display: flex;flex-direction: column;align-items: flex-start;justify-content: space-between;color: #818181;
                                margin-top: 10px;text-align: left">
                                    <div>1、有扎实的编程能力和抽象能力，有优秀的设计和代码品位，热爱编程
                                    </div>
                                    <div style="margin: 10px 0px">2、有良好的数据结构和算法基础</div>
                                    <div>3、熟悉VC++开发环境</div>
                                    <div style="margin: 10px 0px">4、熟悉常用的数据库如: oracle、mysql、redis</div>
                                    <div>5、参与过大型C++项目优先。有分布式或多线程编程经验优先6、有想象力，好奇心，可以阅读英文文献</div>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                </el-col>

                <div>
                    <img src="@/../static/iamges/hire/zp_conbgrd.png" style="position: absolute;bottom:0%;right:0%">
                </div>


            </el-row>

            <el-row class="detail-text"
                    style="border: 2px solid #eeeeee;margin-top: 20px;position: relative;padding-bottom: 20px;  ">
                <div>
                    <img style="position: absolute;top:0%;left: 0%" src="@/../static/iamges/hire/zp_conbglu.png">
                </div>

                <el-col class="detail-left" :lg="{span:10}" :md="{span:10}" :sm="{span:10}" :xs="{span:20}"
                        style="margin-top: 30px">
                    <el-row>
                        <el-col :lg="{span:6,offset:1}" :md="{span:7 ,offset:1}" :sm="{span:7 ,offset:1}"
                                :xs="{span:8 ,offset:3}">
                            <img style=" width: 90px; height: 40px;" src="@/../static/iamges/hire/zp_conl.png">
                            <div style="font-size: 22px;color: white ;position: relative;top:-38px;">职位</div>
                        </el-col>
                        <el-col :lg="{span:10 ,offset:0}" :md="{span:10 ,offset:0}" :sm="{span:10 ,offset:0}"
                                :xs="{span:12 ,offset:0}">
                            <div style="width: 190px">
                                <img style="margin-left: 0px; width: 190px; height: 40px;"
                                     src="@/../static/iamges/hire/zp_conr.png">
                                <div style="font-size: 22px;color: #000000c7;position: relative;top:-38px;">前端开发工程师
                                </div>
                            </div>

                        </el-col>

                    </el-row>
                    <el-row>
                        <el-col :lg="{span:16 ,offset:1}" :md="{span:16 ,offset:1}" :sm="{span:16 ,offset:1}"
                                :xs="{span:16 ,offset:8}" style="margin-left: 35px">
                            <div style="display: flex;justify-content: flex-start;align-items: center">
                                <div style="color: #7b9d38;margin-top: -20px;width: 85px">
                                    工作地点
                                </div>
                                <div style="margin-top: -20px ;width: 30px;text-align: left;margin-left: 3px">
                                    <img style="width: 9px;" src="@/../static/iamges/hire/zp_condotgrey.png">

                                </div>
                                <div style="color:#000000c7;margin-top: -20px;width: 85px;;text-align: left">苏州</div>
                            </div>
                        </el-col>
                    </el-row>


                    <el-row style="margin-top: 40px">
                        <el-col :lg="{span:16 ,offset:1}" :md="{span:16 ,offset:1}" :sm="{span:16 ,offset:1}"
                                :xs="{span:16 ,offset:1}" style="margin-left: 35px">
                            <div style="display: flex;justify-content: flex-start;align-items: center">
                                <div style="color: #7b9d38;margin-top: -20px;width: 85px">
                                    学历要求
                                </div>
                                <div style="margin-top: -20px ;width: 30px;text-align: left;margin-left: 3px">
                                    <img style="width: 9px;" src="@/../static/iamges/hire/zp_condotgrey.png">

                                </div>
                                <div style="color:#000000c7;margin-top: -20px;width: 85px;text-align: left">本科以上</div>
                            </div>
                        </el-col>
                    </el-row>


                    <el-row style="color: #818181;text-align: left;margin-top: 30px">
                        <el-col :span="24" :offset="0" style="margin-left: 48px">
                            如有意向<br/>
                            请将您的简历发送至 hr@medppp.com
                        </el-col>
                    </el-row>

                </el-col>
                <el-col style="margin-top: 30px" class="detail-center" :lg="{span:1}" :md="{span:1}" :sm="{span:1}"
                        :xs="{span:0}">
                    <div style="height: 85%;width: 2px;background-color: #eeeeee">
                    </div>


                </el-col>
                <el-col style="margin-top: 40px" class="detail-right" :lg="{span:13}" :md="{span:13}" :sm="{span:13}"
                        :xs="{span:22,offset:2}">
                    <el-row>
                        <el-col :lg="{span:20}" :md="{span:20}" :sm="{span:20}" :xs="{span:20}">
                            <div>
                                <div style="display: flex;justify-content: flex-start;align-items: center">
                                    <img style="width: 9px;height: 9px"
                                         src="@/../static/iamges/hire/zp_condotorange.png">
                                    <div style="font-weight: 600;margin-left: 10px;font-size: 15px ;color: #2e2e2e">岗位职责
                                    </div>

                                </div>
                                <div style="display: flex;flex-direction: column;align-items: flex-start;justify-content: space-between;color: #818181;
                                margin-top: 10px;text-align: left">
                                    <div>1、负责可视化平台及组件的框架设计和研发，配合交互视觉，完成设计稿高还原度的实现

                                    </div>
                                    <div style="margin: 10px 0px">2、开发以业务分析为主的数据产品和展示为主的大屏项目，负责项目开发中重点、难点技术攻坚</div>
                                    <div>3、参与前端标准化，探索前端新技术并落地实践</div>
                                </div>
                            </div>

                            <div style="margin-top: 20px">
                                <div style="display: flex;justify-content: flex-start;align-items: center">
                                    <img style="width: 9px;height: 9px"
                                         src="@/../static/iamges/hire/zp_condotorange.png">
                                    <div style="font-weight: 600;margin-left: 10px;font-size: 15px ;color: #2e2e2e">应聘要求
                                    </div>

                                </div>
                                <div style="display: flex;flex-direction: column;align-items: flex-start;justify-content: space-between;color: #818181;
                                margin-top: 10px;text-align: left">
                                    <div>1、本科及以上学历，计算机相关专业


                                    </div>
                                    <div style="margin: 10px 0px"> 2、有扎实的编程能力和抽象能力，有优秀的设计和代码品位，热爱编程</div>
                                    <div>3、有良好的数据结构和算法基础</div>
                                    <div style="margin: 10px 0px">
                                        4、熟悉javascript，html5，jQuery。对前端图形库有足够的了解，知道前端图形绘制的各项技术方案
                                    </div>
                                    <div> 5、熟练运用layui，有微信手机页面开发经验的，可作为加分项</div>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                </el-col>

                <div>
                    <img src="@/../static/iamges/hire/zp_conbgrd.png" style="position: absolute;bottom:0%;right:0%">
                </div>


            </el-row>

<!--            <el-row class="detail-text" style="border: 2px solid #eeeeee;margin-top: 20px;position: relative ;padding-bottom: 20px; ">-->
<!--                <div>-->
<!--                    <img style="position: absolute;top:0%;left: 0%" src="@/../static/iamges/hire/zp_conbglu.png">-->
<!--                </div>-->
<!--                <el-col class="detail-left" :lg="{span:10}" :md="{span:10}" :sm="{span:10}" :xs="{span:20}" style="margin-top: 30px">-->
<!--                    <el-row>-->
<!--                        <el-col :lg="{span:6,offset:1}" :md="{span:7 ,offset:1}" :sm="{span:7 ,offset:1}" :xs="{span:8 ,offset:3}">-->
<!--                            <img style=" width: 90px; height: 40px;" src="@/../static/iamges/hire/zp_conl.png">-->
<!--                            <div style="font-size: 22px;color: white ;position: relative;top:-38px;">职位</div>-->
<!--                        </el-col>-->
<!--                        <el-col :lg="{span:10 ,offset:0}" :md="{span:10 ,offset:0}" :sm="{span:10 ,offset:0}"-->
<!--                                :xs="{span:12 ,offset:0}">-->
<!--                            <div style="width: 190px">-->
<!--                                <img style="margin-left: 0px; width: 190px; height: 40px;" src="@/../static/iamges/hire/zp_conr.png">-->
<!--                                <div style="font-size: 22px;color: #000000c7;position: relative;top:-38px;">装配、检验员</div>-->
<!--                            </div>-->
<!--                        </el-col>-->
<!--                    </el-row>-->
<!--                    <el-row>-->
<!--                        <el-col :lg="{span:16 ,offset:1}" :md="{span:16 ,offset:1}" :sm="{span:16 ,offset:1}"-->
<!--                                :xs="{span:16 ,offset:8}" style="margin-left: 35px">-->
<!--                            <div style="display: flex;justify-content: flex-start;align-items: center">-->
<!--                                <div style="color: #7b9d38;margin-top: -20px;width: 85px">工作地点</div>-->
<!--                                <div style="margin-top: -20px ;width: 30px;text-align: left;margin-left: 3px">-->
<!--                                    <img style="width: 9px;" src="@/../static/iamges/hire/zp_condotgrey.png">-->
<!--                                </div>-->
<!--                                <div style="color:#000000c7;margin-top: -20px;width: 85px;;text-align: left">苏州</div>-->
<!--                            </div>-->
<!--                        </el-col>-->
<!--                    </el-row>-->


<!--                    <el-row style="margin-top: 40px">-->
<!--                        <el-col :lg="{span:16 ,offset:1}" :md="{span:16 ,offset:1}" :sm="{span:16 ,offset:1}"-->
<!--                                :xs="{span:16 ,offset:1}" style="margin-left: 35px">-->
<!--                            <div style="display: flex;justify-content: flex-start;align-items: center">-->
<!--                                <div style="color: #7b9d38;margin-top: -20px;width: 85px">-->
<!--                                    学历要求-->
<!--                                </div>-->
<!--                                <div style="margin-top: -20px ;width: 30px;text-align: left;margin-left: 3px">-->
<!--                                    <img style="width: 9px;" src="@/../static/iamges/hire/zp_condotgrey.png">-->

<!--                                </div>-->
<!--                                <div style="color:#000000c7;margin-top: -20px;width: 85px;text-align: left">大专以上</div>-->
<!--                            </div>-->
<!--                        </el-col>-->
<!--                    </el-row>-->


<!--                    <el-row style="color: #818181;text-align: left;margin-top: 30px">-->
<!--                        <el-col :span="24" :offset="0" style="margin-left: 48px">-->
<!--                            如有意向<br/>-->
<!--                            请将您的简历发送至 hr@medppp.com-->
<!--                        </el-col>-->
<!--                    </el-row>-->

<!--                </el-col>-->
<!--                <el-col style="margin-top: 30px" class="detail-center" :lg="{span:1}" :md="{span:1}" :sm="{span:1}"-->
<!--                        :xs="{span:0}">-->
<!--                    <div style="height: 85%;width: 2px;background-color: #eeeeee">-->
<!--                    </div>-->
<!--                </el-col>-->

<!--                <el-col style="margin-top: 40px" class="detail-right" :lg="{span:13}" :md="{span:13}" :sm="{span:13}" :xs="{span:22,offset:2}">-->
<!--                    <el-row>-->
<!--                        <el-col :lg="{span:20}" :md="{span:20}" :sm="{span:20}" :xs="{span:20}">-->
<!--                            <div>-->
<!--                                <div style="display: flex;justify-content: flex-start;align-items: center">-->
<!--                                    <img style="width: 9px;height: 9px" src="@/../static/iamges/hire/zp_condotorange.png">-->
<!--                                    <div style="font-weight: 600;margin-left: 10px;font-size: 15px ;color: #2e2e2e">岗位职责</div>-->
<!--                                </div>-->
<!--                                <div style="display: flex;flex-direction: column;align-items: flex-start;justify-content: space-between;color: #818181; margin-top: 10px;text-align: left">-->
<!--                                    <div>1、医疗器械产品装配</div>-->
<!--                                    <div style="margin: 10px 0px;">2、根据检验标准，完成进料检验工作</div>-->
<!--                                    <div>3、根据检验标准，完成生产制程、成品及出货检验工作</div>-->
<!--                                    <div style="margin: 10px 0px">4、如实记录检验结果，提交检验报表</div>-->
<!--                                    <div>5、不良品 (原材料、半成品及成品) 的反馈及处理</div>-->
<!--                                    <div style="margin: 10px 0px">6、监督及检查仓库、生产车间现场的5S及GMP管理要求</div>-->
<!--                                    <div>7、协助处理客户投诉的调查分析，及纠正改进</div>-->

<!--                                </div>-->
<!--                            </div>-->

<!--                            <div style="margin-top: 20px">-->
<!--                                <div style="display: flex;justify-content: flex-start;align-items: center">-->
<!--                                    <img style="width: 9px;height: 9px"-->
<!--                                         src="@/../static/iamges/hire/zp_condotorange.png">-->
<!--                                    <div style="font-weight: 600;margin-left: 10px;font-size: 15px ;color: #2e2e2e">应聘要求-->
<!--                                    </div>-->

<!--                                </div>-->
<!--                                <div style="display: flex;flex-direction: column;align-items: flex-start;justify-content: space-between;color: #818181;-->
<!--                                margin-top: 10px;text-align: left">-->
<!--                                    <div>1、电子或计算机等相关理工科专业，大专以上学历</div>-->
<!--                                    <div style="margin: 10px 0px">2、有1-2年装配、检验员工作经历，医疗器械电子产品优先</div>-->
<!--                                    <div>3、有独立完成进料、制成、成品及出货检验的任一相关经历</div>-->
<!--                                    <div style="margin: 10px 0px">4、有质量报表的记录相关经验</div>-->
<!--                                    <div>5、熟练使用基本统计报表办公软件，如Excel</div>-->
<!--                                    <div style="margin: 10px 0px">6、数量操作基本检验工具、检测设备等</div>-->

<!--                                </div>-->
<!--                            </div>-->
<!--                        </el-col>-->
<!--                    </el-row>-->
<!--                </el-col>-->
<!--              <div>-->
<!--                <img src="@/../static/iamges/hire/zp_conbgrd.png" style="position: absolute;bottom:0%;right:0%">-->
<!--              </div>-->
<!--            </el-row>-->


            <el-row class="detail-text" style="border: 2px solid #eeeeee;margin-top: 20px;position: relative ;padding-bottom: 20px; ">
                <div>
                    <img style="position: absolute;top:0%;left: 0%" src="@/../static/iamges/hire/zp_conbglu.png">
                </div>
                <el-col class="detail-left" :lg="{span:10}" :md="{span:10}" :sm="{span:10}" :xs="{span:20}" style="margin-top: 30px">
                    <el-row>
                        <el-col :lg="{span:6,offset:1}" :md="{span:7 ,offset:1}" :sm="{span:7 ,offset:1}" :xs="{span:8 ,offset:3}">
                            <img style=" width: 90px; height: 40px;" src="@/../static/iamges/hire/zp_conl.png">
                            <div style="font-size: 22px;color: white ;position: relative;top:-38px;">职位</div>
                        </el-col>
                        <el-col :lg="{span:10 ,offset:0}" :md="{span:10 ,offset:0}" :sm="{span:10 ,offset:0}"
                                :xs="{span:12 ,offset:0}">
                            <div style="width: 190px">
                                <img style="margin-left: 0px; width: 190px; height: 40px;" src="@/../static/iamges/hire/zp_conr.png">
                                <div style="font-size: 22px;color: #000000c7;position: relative;top:-38px;">设备组装工程师</div>
                            </div>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :lg="{span:16 ,offset:1}" :md="{span:16 ,offset:1}" :sm="{span:16 ,offset:1}" :xs="{span:16 ,offset:8}" style="margin-left: 35px">
                            <div style="display: flex;justify-content: flex-start;align-items: center">
                                <div style="color: #7b9d38;margin-top: -20px;width: 85px">工作地点</div>
                                <div style="margin-top: -20px ;width: 30px;text-align: left;margin-left: 3px">
                                    <img style="width: 9px;" src="@/../static/iamges/hire/zp_condotgrey.png">
                                </div>
                                <div style="color:#000000c7;margin-top: -20px;width: 85px;;text-align: left">苏州</div>
                            </div>
                        </el-col>
                    </el-row>


                    <el-row style="margin-top: 40px">
                        <el-col :lg="{span:16 ,offset:1}" :md="{span:16 ,offset:1}" :sm="{span:16 ,offset:1}"
                                :xs="{span:16 ,offset:1}" style="margin-left: 35px">
                            <div style="display: flex;justify-content: flex-start;align-items: center">
                                <div style="color: #7b9d38;margin-top: -20px;width: 85px">
                                    学历要求
                                </div>
                                <div style="margin-top: -20px ;width: 30px;text-align: left;margin-left: 3px">
                                    <img style="width: 9px;" src="@/../static/iamges/hire/zp_condotgrey.png">

                                </div>
                                <div style="color:#000000c7;margin-top: -20px;width: 85px;text-align: left">大专以上</div>
                            </div>
                        </el-col>
                    </el-row>


                    <el-row style="color: #818181;text-align: left;margin-top: 30px">
                        <el-col :span="24" :offset="0" style="margin-left: 48px">
                            如有意向<br/>
                            请将您的简历发送至 hr@medppp.com
                        </el-col>
                    </el-row>

                </el-col>
                <el-col style="margin-top: 30px" class="detail-center" :lg="{span:1}" :md="{span:1}" :sm="{span:1}"
                        :xs="{span:0}">
                    <div style="height: 85%;width: 2px;background-color: #eeeeee">
                    </div>
                </el-col>

                <el-col style="margin-top: 40px" class="detail-right" :lg="{span:13}" :md="{span:13}" :sm="{span:13}" :xs="{span:22,offset:2}">
                    <el-row>
                        <el-col :lg="{span:20}" :md="{span:20}" :sm="{span:20}" :xs="{span:20}">
                            <div>
                                <div style="display: flex;justify-content: flex-start;align-items: center">
                                    <img style="width: 9px;height: 9px" src="@/../static/iamges/hire/zp_condotorange.png">
                                    <div style="font-weight: 600;margin-left: 10px;font-size: 15px ;color: #2e2e2e">岗位职责</div>
                                </div>
                                <div style="display: flex;flex-direction: column;align-items: flex-start;justify-content: space-between;color: #818181; margin-top: 10px;text-align: left">
                                    <div>1、医疗器械产品装配</div>
                                    <div style="margin: 10px 0px;">2、严格按图纸、工艺文件要求加工，对已加工产品作好自检，确保产品合格</div>
                                    <div>3、按照要求认真、及时、清楚、完整的填写各种生产记录、设备运行记录等</div>
                                    <div style="margin: 10px 0px">4、严格按设备的操作规程正确操作设备，按要求作好设备日常维护、 保养工作</div>
                                    <div>5、正确使用计量器具并做好维护、保养工作</div>
                                    <div style="margin: 10px 0px">6、遵守厂纪厂规，服从领导安排，完成每日的生产任务</div>
                                </div>
                            </div>

                            <div style="margin-top: 20px">
                                <div style="display: flex;justify-content: flex-start;align-items: center">
                                    <img style="width: 9px;height: 9px"
                                         src="@/../static/iamges/hire/zp_condotorange.png">
                                    <div style="font-weight: 600;margin-left: 10px;font-size: 15px ;color: #2e2e2e">应聘要求
                                    </div>

                                </div>
                                <div style="display: flex;flex-direction: column;align-items: flex-start;justify-content: space-between;color: #818181;
                                margin-top: 10px;text-align: left">
                                    <div>1、电子或计算机等相关理工科专业，大专以上学历</div>
                                    <div style="margin: 10px 0px">2、有1-2年装配工作经历，具有医疗器械/设备相关工作经验</div>
                                    <div>3、有独立完成进料、制成和成品的任一相关经历</div>
                                    <div style="margin: 10px 0px">4、有质量报表的记录相关经验</div>
                                    <div>5、熟练使用基本统计报表办公软件，如Excel</div>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                </el-col>

                <div>
                    <img src="@/../static/iamges/hire/zp_conbgrd.png" style="position: absolute;bottom:0%;right:0%">
                </div>
            </el-row>

            <el-row class="detail-text" style="border: 2px solid #eeeeee;margin-top: 20px;position: relative ;padding-bottom: 20px; ">
                <div>
                    <img style="position: absolute;top:0%;left: 0%" src="@/../static/iamges/hire/zp_conbglu.png">
                </div>
                <el-col class="detail-left" :lg="{span:10}" :md="{span:10}" :sm="{span:10}" :xs="{span:20}" style="margin-top: 30px">
                    <el-row>
                        <el-col :lg="{span:6,offset:1}" :md="{span:7 ,offset:1}" :sm="{span:7 ,offset:1}" :xs="{span:8 ,offset:3}">
                            <img style=" width: 90px; height: 40px;" src="@/../static/iamges/hire/zp_conl.png">
                            <div style="font-size: 22px;color: white ;position: relative;top:-38px;">职位</div>
                        </el-col>
                        <el-col :lg="{span:10 ,offset:0}" :md="{span:10 ,offset:0}" :sm="{span:10 ,offset:0}"
                                :xs="{span:12 ,offset:0}">
                            <div style="width: 190px">
                                <img style="margin-left: 0px; width: 190px; height: 40px;" src="@/../static/iamges/hire/zp_conr.png">
                                <div style="font-size: 22px;color: #000000c7;position: relative;top:-38px;">检验员</div>
                            </div>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :lg="{span:16 ,offset:1}" :md="{span:16 ,offset:1}" :sm="{span:16 ,offset:1}" :xs="{span:16 ,offset:8}" style="margin-left: 35px">
                            <div style="display: flex;justify-content: flex-start;align-items: center">
                                <div style="color: #7b9d38;margin-top: -20px;width: 85px">工作地点</div>
                                <div style="margin-top: -20px ;width: 30px;text-align: left;margin-left: 3px">
                                    <img style="width: 9px;" src="@/../static/iamges/hire/zp_condotgrey.png">
                                </div>
                                <div style="color:#000000c7;margin-top: -20px;width: 210px;;text-align: left">苏州</div>
                            </div>
                        </el-col>
                    </el-row>


                    <el-row style="margin-top: 40px">
                        <el-col :lg="{span:16 ,offset:1}" :md="{span:16 ,offset:1}" :sm="{span:16 ,offset:1}"
                                :xs="{span:16 ,offset:1}" style="margin-left: 35px">
                            <div style="display: flex;justify-content: flex-start;align-items: center">
                                <div style="color: #7b9d38;margin-top: -20px;width: 85px">
                                    学历要求
                                </div>
                                <div style="margin-top: -20px ;width: 30px;text-align: left;margin-left: 3px">
                                    <img style="width: 9px;" src="@/../static/iamges/hire/zp_condotgrey.png">

                                </div>
                                <div style="color:#000000c7;margin-top: -20px;width: 210px;text-align: left">中专（或相当）以上学历</div>
                            </div>
                        </el-col>
                    </el-row>


                    <el-row style="color: #818181;text-align: left;margin-top: 30px">
                        <el-col :span="24" :offset="0" style="margin-left: 48px">
                            如有意向<br/>
                            请将您的简历发送至 hr@medppp.com
                        </el-col>
                    </el-row>

                </el-col>
                <el-col style="margin-top: 30px" class="detail-center" :lg="{span:1}" :md="{span:1}" :sm="{span:1}"
                        :xs="{span:0}">
                    <div style="height: 85%;width: 2px;background-color: #eeeeee">
                    </div>
                </el-col>

                <el-col style="margin-top: 40px" class="detail-right" :lg="{span:13}" :md="{span:13}" :sm="{span:13}" :xs="{span:22,offset:2}">
                    <el-row>
                        <el-col :lg="{span:20}" :md="{span:20}" :sm="{span:20}" :xs="{span:20}">
                            <div>
                                <div style="display: flex;justify-content: flex-start;align-items: center">
                                    <img style="width: 9px;height: 9px" src="@/../static/iamges/hire/zp_condotorange.png">
                                    <div style="font-weight: 600;margin-left: 10px;font-size: 15px ;color: #2e2e2e">岗位职责</div>
                                </div>
                                <div style="display: flex;flex-direction: column;align-items: flex-start;justify-content: space-between;color: #818181; margin-top: 10px;text-align: left">
                                    <div>1、按照各相关检验规程进行检验，并对检验数据的真实性负责，同时做好记录</div>
                                    <div style="margin: 10px 0px;">2、负责进料检验、过程检验和成品检验中的物理性能检测，做好产品生产过程中的检验把关，对错、漏检产生的后果负责</div>
                                    <div>3、认真对进货物品和半成品进行标识，确保追溯；对生产过程中发生异常波动和质量问题，及时反馈信息给质量部经理，为有关部门采取纠正和预防措施提供依据</div>
                                    <div style="margin: 10px 0px">4、有对进货物品和半成品作出合格与否判定的权力，并有对非批量性进货物品和半成品判定是否让步接受的权力</div>
                                    <div>5、有对产品生产过程中进行监督检查、对不合格（非批量性）进行仲裁的权力</div>
                                    <div style="margin: 10px 0px">6、协助质量部经理对相关的纠正预防措施和客户抱怨的处置等实施跟踪验证</div>
                                    <div>7、对发生的质量事故，有提出追查和处理意见的权利</div>
                                  <div style="margin: 10px 0px">8、完成领导交办的其他工作任务</div>
                                </div>
                            </div>

                            <div style="margin-top: 20px">
                                <div style="display: flex;justify-content: flex-start;align-items: center">
                                    <img style="width: 9px;height: 9px"
                                         src="@/../static/iamges/hire/zp_condotorange.png">
                                    <div style="font-weight: 600;margin-left: 10px;font-size: 15px ;color: #2e2e2e">应聘要求
                                    </div>

                                </div>
                                <div style="display: flex;flex-direction: column;align-items: flex-start;justify-content: space-between;color: #818181;
                                margin-top: 10px;text-align: left">
                                    <div>1、中专（或相当）以上学历；掌握机械、电子的专业知识；熟悉各种量具的使用</div>
                                    <div style="margin: 10px 0px">2、具有一定的判断能力，有一定的执行能力等</div>
                                    <div>3、具有一年检验工作经验</div>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                </el-col>

                <div>
                    <img src="@/../static/iamges/hire/zp_conbgrd.png" style="position: absolute;bottom:0%;right:0%">
                </div>
            </el-row>

            <el-row class="detail-text" style="border: 2px solid #eeeeee;margin-top: 20px;position: relative ;padding-bottom: 20px; ">
                <div>
                    <img style="position: absolute;top:0%;left: 0%" src="@/../static/iamges/hire/zp_conbglu.png">
                </div>

                <el-col class="detail-left" :lg="{span:10}" :md="{span:10}" :sm="{span:10}" :xs="{span:20}"
                        style="margin-top: 30px">
                    <el-row>
                        <el-col :lg="{span:6,offset:1}" :md="{span:7 ,offset:1}" :sm="{span:7 ,offset:1}"
                                :xs="{span:8 ,offset:3}">
                            <img style=" width: 90px; height: 40px;" src="@/../static/iamges/hire/zp_conl.png">
                            <div style="font-size: 22px;color: white ;position: relative;top:-38px;">职位</div>
                        </el-col>
                        <el-col :lg="{span:10 ,offset:0}" :md="{span:10 ,offset:0}" :sm="{span:10 ,offset:0}"
                                :xs="{span:12 ,offset:0}">
                            <div style="width: 190px">
                                <img style="margin-left: 0px; width: 190px; height: 40px;"
                                     src="@/../static/iamges/hire/zp_conr.png">
                                <div style="font-size: 22px;color: #000000c7;position: relative;top:-38px;">医疗器械销售</div>
                            </div>

                        </el-col>

                    </el-row>
                    <el-row>
                        <el-col :lg="{span:16 ,offset:1}" :md="{span:16 ,offset:1}" :sm="{span:16 ,offset:1}"
                                :xs="{span:16 ,offset:8}" style="margin-left: 35px">
                            <div style="display: flex;justify-content: flex-start;align-items: center">
                                <div style="color: #7b9d38;margin-top: -20px;width: 85px">
                                    工作地点
                                </div>
                                <div style="margin-top: -20px ;width: 30px;text-align: left;margin-left: 3px">
                                    <img style="width: 9px;" src="@/../static/iamges/hire/zp_condotgrey.png">

                                </div>
                                <div style="color:#000000c7;margin-top: -20px;width: 85px;;text-align: left">苏州</div>
                            </div>
                        </el-col>
                    </el-row>


                    <el-row style="margin-top: 40px">
                        <el-col :lg="{span:16 ,offset:1}" :md="{span:16 ,offset:1}" :sm="{span:16 ,offset:1}"
                                :xs="{span:16 ,offset:1}" style="margin-left: 35px">
                            <div style="display: flex;justify-content: flex-start;align-items: center">
                                <div style="color: #7b9d38;margin-top: -20px;width: 85px">
                                    学历要求
                                </div>
                                <div style="margin-top: -20px ;width: 30px;text-align: left;margin-left: 3px">
                                    <img style="width: 9px;" src="@/../static/iamges/hire/zp_condotgrey.png">

                                </div>
                                <div style="color:#000000c7;margin-top: -20px;width: 85px;text-align: left">专科以上</div>
                            </div>
                        </el-col>
                    </el-row>


                    <el-row style="color: #818181;text-align: left;margin-top: 30px">
                        <el-col :span="24" :offset="0" style="margin-left: 48px">
                            如有意向<br/>
                            请将您的简历发送至 hr@medppp.com
                        </el-col>
                    </el-row>

                </el-col>
                <el-col style="margin-top: 30px" class="detail-center" :lg="{span:1}" :md="{span:1}" :sm="{span:1}"
                        :xs="{span:0}">
                    <div style="height: 85%;width: 2px;background-color: #eeeeee">
                    </div>


                </el-col>
                <el-col style="margin-top: 40px" class="detail-right" :lg="{span:13}" :md="{span:13}" :sm="{span:13}"
                        :xs="{span:22,offset:2}">
                    <el-row>
                        <el-col :lg="{span:20}" :md="{span:20}" :sm="{span:20}" :xs="{span:20}">
                            <div>
                                <div style="display: flex;justify-content: flex-start;align-items: center">
                                    <img style="width: 9px;height: 9px"
                                         src="@/../static/iamges/hire/zp_condotorange.png">
                                    <div style="font-weight: 600;margin-left: 10px;font-size: 15px ;color: #2e2e2e">岗位职责
                                    </div>

                                </div>
                                <div style="display: flex;flex-direction: column;align-items: flex-start;justify-content: space-between;color: #818181;
                                margin-top: 10px;text-align: left">
                                    <div>1、熟悉产品知识，根据市场需求，对区域内市场客户提供产品介绍、培训及咨询</div>
                                    <div style="margin: 10px 0px">2、管理客户关系，及时收集、反馈、解决产品推广过程中的各种意见和建议</div>
                                    <div>3、负责区域内客户的定期维护拜访、市场推广及产品的宣传活动</div>
                                    <div style="margin: 10px 0px">4、规划并完善行业目标客户群的拓展策划，完成预期销售目标</div>
                                    <div>5、主要负责区域:全国</div>

                                </div>
                            </div>

                            <div style="margin-top: 20px">
                                <div style="display: flex;justify-content: flex-start;align-items: center">
                                    <img style="width: 9px;height: 9px"
                                         src="@/../static/iamges/hire/zp_condotorange.png">
                                    <div style="font-weight: 600;margin-left: 10px;font-size: 15px ;color: #2e2e2e">应聘要求
                                    </div>

                                </div>
                                <div style="display: flex;flex-direction: column;align-items: flex-start;justify-content: space-between;color: #818181;
                                margin-top: 10px;text-align: left">
                                    <div>1、专科及以上学历，市场营销或医学相关专业优先</div>
                                    <div style="margin: 10px 0px">2、有三年以上医疗器械销售工作经验</div>
                                    <div>3、性格外向、反应敏捷、表达能力强，具有较强的沟通能力及交际技巧，具有亲和力</div>
                                    <div style="margin: 10px 0px">4、具备一定的市场分析及判断能力，良好的客户服务意识</div>
                                    <div>5、有责任心，能承受较大的工作压力</div>

                                </div>
                            </div>
                        </el-col>
                    </el-row>
                </el-col>

                <div>
                    <img src="@/../static/iamges/hire/zp_conbgrd.png" style="position: absolute;bottom:0%;right:0%">
                </div>


            </el-row>


        </div>

    </div>
</template>

<script>
    export default {
        name: "recruit",

        mounted() {
            this.$emit("handleSelect", "5", ["5"])
        },
    }
</script>

<style scoped>

    @media only screen and (max-width: 992px) {
        .zhaopin-detail {
            width: 90vw;
            margin: auto;
            margin-top: 35px;
            margin-bottom: 20px;


        }

    }


    @media only screen and (min-width: 992px) {
        .zhaopin-detail {
            width: 60vw;
            margin: auto;
            margin-top: 35px;
            margin-bottom: 20px;


        }

        .detail-text {
            display: flex;

        }
    }


</style>
