<template>
  <div class="index">
    <el-container>
      <el-header class="header">
        <div class="hidden-sm-and-down header-block">
          <span :lg="{span:8,offset:4}" :md="{span:12,offset:0}" :sm="{span:12,offset:0}" class="header-logo">
              <img src="@/../static/iamges/home/home_logo.jpg">
          </span>
          <span class="header-menu" :lg="{span:8,offset:4}" :md="{span:12,offset:0}" :sm="{span:12,offset:0}"
                style="margin-top: 22px">
            <el-menu ref="menu" class="el-menu-demo" mode="horizontal" router text-color="#000000"
                     :default-active='$router.currentRoute.path' active-text-color="#7b9d38" @select="handleSelect">
                <el-menu-item route="/homepage" :style="menuItemStyle[0]" index="0">首页</el-menu-item>
                <el-menu-item route="/aboutUs" index="3" :style="menuItemStyle[3]">关于我们</el-menu-item>
                <el-menu-item :style="menuItemStyle[6]">
                  <a style="text-decoration: none" href="https://www.turingene.com/#/slideShow?id=3" target="_blank">云切片库</a>
                </el-menu-item>
                <el-menu-item route="/recruit" :style="menuItemStyle[5]" index="5">人才招聘</el-menu-item>
            </el-menu>
          </span>
          <div class="header-login">
            <span :lg="{span:4,offset:0}" :md="{span:0,offset:0}" :sm="{span:0,offset:0}" class="header-login">
              <a href="https://scan.turingene.com/management/" title="登录">
                <img src="@/../static/iamges/home/slide.svg">
              </a>
            </span>
          </div>
        </div>
        <el-row class="hidden-md-and-up" style="display: flex;align-items: center;">
          <el-col :span="3" :offset="0" class="header-title">
            <img src="@/../static/iamges/home/home_logo.jpg">
          </el-col>
          <el-col :span="12" :offset="11">
            <a href="https://scan.turingene.com/management/" style="font-size: 20px" title="登录">
              <img src="@/../static/iamges/home/slide.svg">
            </a>
          </el-col>
          <el-col :span="20" :offset="1">
            <el-button @click="drawer = true" type="text" style="display: flex;align-items: center;">
              <i class="el-icon-menu" style="font-size: 30px;color: #282828"/>
            </el-button>
          </el-col>
        </el-row>
      </el-header>
      <el-main style="height: 100%;width: 100%;">
        <router-view v-on:handleSelect="handleSelect"/>
      </el-main>

      <div class="contactUs">
        <div class="left">
          <el-row>
            <el-col>
              <div style=" display: flex;  flex-direction: column;  align-items: center; ">
                <div><img src="@/../static/iamges/home/home_bottom1.jpg"></div>
                <div style=" display: flex; flex-direction: row; justify-content: space-between;margin-top: 20px; ">
                  <div style="margin-left: 32px;">
                    <img src="@/../static/iamges/home/home_bottom2.jpg">
                  </div>
                  <div style=" display: flex;  flex-direction: column;  align-items: center; margin-left: 15px">
                    <img src="@/../static/iamges/home/home_bottom3.jpg">
                    <span
                        style="text-align: center;color: #FFF;width: 209px;font-size: xx-small;margin-top: 10px;font-weight: bolder">
                      苏州迪美格智能科技有限公司<br/>版权所有
                    </span>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>

        <div class="right">
          <el-row style="margin-top: 15px">
            <el-row>
              <el-col :lg="{span:10,offset:2}" :md="{span:24,offset:0}">
                <el-row style="display: flex; align-items: center;">
                  <el-col :lg="{span:2}" :md="{span:1,offset:0}" :sm="{span:1,offset:8}"
                          :xs="{span:1,offset:8}" style="border: 1px solid  #d2d2d2"/>
                  <el-col class="contact-us-title" :lg="{span:10,offset:2}" :md="{span:5,offset:1}" :sm="{span:6}"
                          :xs="{span:6}" style="font-size: 24px;color: #ffffff;margin-left: 50px">联系我们
                  </el-col>
                  <el-col :lg="{span:2,offset:0}" :md="{span:1,offset:0}" :sm="{span:1}"
                          :xs="{span:1}" style="border: 1px solid #d2d2d2"/>
                </el-row>
              </el-col>
            </el-row>


            <el-col :lg="{span:8,offset:2}" :md="{span:24}" :sm="{span:24}" style="margin-top: 10px">
              <el-row class="contactUs-detail" v-for="(item,index) in contactUs" :key="index" style="margin-top: 15px">
                <el-col :lg="{span:2,offset:0}" :md="{span:2,offset:0}" :sm="{span:2,offset:3}">
                  <img :src="item.icon">
                </el-col>
                <el-col :lg="{span:5,offset:1}" :md="{span:14}" :sm="{span:14}" style="color: white;font-weight: 600;white-space: nowrap;
font-size: 14px;">
                  {{ item.text }}
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </div>
      </div>


      <div class="footer">版权所有 : 苏州迪美格智能科技有限公司 &nbsp;
        <span>
          <a href="http://beian.miit.gov.cn" target="_blank" rel="nofollow"
             style="color: #666666;white-space: nowrap;text-decoration: none">
            苏ICP备17054065号-4
          </a>
        </span>
      </div>
    </el-container>

    <!--  以下内容暂时无效  -->
    <el-drawer :visible.sync="drawer" direction="rtl" :with-header="true" size="90%">
      <template slot="title">
        <span/>
      </template>
      <span>
        <el-menu ref="mobileMenu" :default-active='$router.currentRoute.path' router text-color="#000000"
                 active-text-color="#7b9d38" @select="handleSelect">
            <el-menu-item route="/homepage" :style="menuItemStyle[0]" index="0">首页</el-menu-item>
            <el-menu-item route="/aboutUs" index="3" :style="menuItemStyle[3]">关于我们</el-menu-item>
            <el-menu-item :style="menuItemStyle[6]">
              <a class="yun" style="text-decoration: none" href="https://www.turingene.com/#/slideShow?id=3"
                 target="_blank">
                云切片库
              </a>
            </el-menu-item>
            <el-menu-item route="/recruit" :style="menuItemStyle[5]" index="5">人才招聘</el-menu-item>
        </el-menu>
      </span>
    </el-drawer>
  </div>
</template>
<script>

export default {
  name: "index",
  data() {
    return {
      activeIndex: '1',
      drawer: false,
      menuSeleted: {backgroundColor: "#ffffff!important", color: "#7b9d38!important", fontSize: "30px"},
      menuUnSeleted: {backgroundColor: "#ffffff!important", color: "#818181!important", fontSize: "24px"},
      menuItemStyle: [
        this.menuSeleted,
        this.menuUnSeleted,
        this.menuUnSeleted,
        this.menuUnSeleted,
        this.menuUnSeleted,
        this.menuUnSeleted,
        this.menuUnSeleted,
      ],
      contactUs: [
        {
          icon: require("@/../static/iamges/home/home_bottomicon1.png"),
          text: "18121496883"
        },
        {
          icon: require("@/../static/iamges/home/home_bottomicon2.png"),
          text: "江苏省苏州市工业园区仁爱路99号D2-410&411"
        },
        {
          icon: require("@/../static/iamges/home/home_bottomicon3.png"),
          text: "product@medppp.com"
        },
        {
          icon: require("@/../static/iamges/home/home_bottomicon4.png"),
          text: "www.turingene.com"
        },
      ],
    }
  },
  methods: {
    handleSelect(key, keyPath) {
      this.menuItemStyle = [
        this.menuUnSeleted,
        this.menuUnSeleted,
        this.menuUnSeleted,
        this.menuUnSeleted,
        this.menuUnSeleted,
        this.menuUnSeleted,
        this.menuUnSeleted,
      ];
      this.menuItemStyle[keyPath[0]] = this.menuSeleted;
      this.menuItemStyle[keyPath[1]] = this.menuSeleted
      this.drawer = false
    },
    gtoSlide() {
      let id = 3;
      this.$router.push({
        query: {
          id: id,
        },
        path: "/slideShow"
      })
    },
  },
  mounted() {
    this.$root.$index = this;
  }
}
</script>

<style scoped lang="css">
.contactUs {
  /*width: 100%;*/
  /*height: 280px;*/
  background-color: #7b9d38;
  padding-top: 20px;

}


/*小于992*/
@media only screen and (max-width: 992px) {


  .btn-group {
    flex-direction: column !important;
  }

  .footer {
    padding: 0px 15px;
  }

  .el-main {
    /*margin-top: 70px;*/
  }

  .el-header {
    height: 120px !important;
    padding-top: 0 !important;
    border-bottom: #7b9d38 10px solid;
  }

  .contactUs {
    text-align: center;
  }
}

/*大于992*/
@media only screen and (min-width: 992px) {
  .left, .right {
    width: 50%;
    height: 280px;
  }

  .contactUs {
    height: 280px;
    display: flex;
  }

  .btn-group {
    flex-direction: row !important;
  }

  .el-main {
    /*margin-top: 100px;*/
  }

  .el-header {
    height: 120px !important;
    padding-top: 0 !important;
    display: flex;
    justify-content: center;
    border-bottom: #7b9d38 10px solid;
  }

  .header-block {
    display: flex;
    width: 80vw;
    border-bottom: 5px solid #d0d0d0;
    justify-content: space-between;
  }

  .header-menu {
    display: flex;
    flex-direction: row-reverse;
  }
}


.header-title {
  font-size: 40px;
  font-weight: 800;
}

.el-main {
  text-align: center;
  padding: 0;

}

.el-menu .el-menu-item {
  font-size: 18px;
}

.el-menu .submenu_title {
  font-size: 18px !important;
}

.el-menu.el-menu--horizontal {
  border: none;
}

.el-submenu /deep/ .el-submenu__title:hover {
  color: #000000 !important;
  background-color: #ffffff !important;
}


.el-menu {
  font-size: 18px;
}


.footer {
  display: flex;
  justify-content: center;
  background-color: #d0d0d0;
  height: 50px;
  line-height: 50px;
  color: #666666;
  font-weight: 500;
  font-size: 13px
}


/*    992-1200之间*/
@media screen and (min-width: 992px)and(max-width: 1200px) {
  .contactUs {
    display: flex;
    height: 280px;
    display: flex;
  }

  .left, .right {
    width: 50%;

  }

  .dmg-text {
    margin-left: -30px;
  }

}

/*    768-992之间*/
@media screen and (min-width: 768px)and(max-width: 992px) {
  .left, .right {
    width: 50%;

  }

  .contactUs {
    display: flex;
    height: 280px;
  }
}

/*    小于768*/
@media only screen and (max-width: 768px) {
  .header-title {
    font-size: 40px;
    font-weight: 800;

  }

  .el-main {
    /*margin-top: 60px;*/
  }

  .left, .right {
    width: 100%;
  }

  .contact-us-title {
    margin-left: 0px !important;
  }

  .footer {
    font-size: 10px !important;
  }
}

.el-menu--horizontal >>> .el-menu-item.is-active {
  border-bottom: #ffffff;
  /*color: #303133;*/
}

.yun:hover {
  color: rgb(129, 129, 129);
}

.yun:link {
  color: rgb(129, 129, 129);
}

.yun:visited {
  color: rgb(129, 129, 129);
}

.yun:active {
  color: #a0c15f;
}

.header-menu {
  flex-grow: 1;
}

.header-login {
  margin: 10px 0 0 0;
  white-space: nowrap; /* 防止链接换行 */
}

</style>
